/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';

import api from '../../services/api';
import { useHistory, useParams } from 'react-router';
import { Paralax, Container, Box, Chaves } from './styles';
import { useAuth } from '../../hooks/auth';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/taca.svg'

interface IUser {
  balance: number;
  coins: number;
  email: string;
  id: string;
  name: string;
}

interface IBedroom {
  id?: string;
  round_current: number;
  round_one: Date;
  round_two: Date;
  round_three: Date;
  round_four: Date;
  status: number;
}


interface IUserBedroom {
  bedroom: IBedroom;
  user: IUser;
  bedroom_id: string;
  id: string;
  position: number;
  round: number;
  round_one: number;
  round_two: number;
  round_three: number;
  round_four: number;
}

interface ParamsProps {
  id: string;
  round: string;
  price: string;
}

export default function Bedroon() {
  const { user } = useAuth();
  const [time, setTime] = useState(12 * 60 * 60);
  const history = useHistory();
  const params = useParams() as ParamsProps;
  const [users1, setUsers1] = useState<IUserBedroom[]>([]);
  const [users2, setUsers2] = useState<IUserBedroom[]>([]);
  const [users3, setUsers3] = useState<IUserBedroom[]>([]);
  const [users4, setUsers4] = useState<IUserBedroom[]>([]);
  const [cham, setCham] = useState<IUser>({} as IUser);
  const [vCham, setVCham] = useState<IUser>({} as IUser);
  const [bedroom, setBedroom] = useState<IBedroom>({} as IBedroom);

  const loadData = useCallback(async () => {
    try {
      switch (params.round) {
        case "1":
          const res = await api.get(`/bedroons/${params.id}/${params.round}`);
          setUsers1(res.data);
          break;
        case "2":
          const res2 = await api.get(`/bedroons/${params.id}/${params.round}`);
          setUsers2(res2.data);
          break;
        case "3":
          const res5 = await api.get(`/bedroons/${params.id}/${params.round}`);
          setUsers3(res5.data);
          break;
        case "4":
          const res9 = await api.get(`/bedroons/${params.id}/${params.round}`);
          setUsers4(res9.data);
          break;
        case "5":
          const champion = await api.get(`/bedroons/champs/${params.id}`);
          setCham(champion.data.champion);
          setVCham(champion.data.second);
          break;
    }
    } catch (err) {
      console.log(err)
    }
  }, [params])

  async function finishRound(round: string) {
    await api.post(`/bedroons/finishround/${params.id}/${round}`);
  }

  async function setTimes() {
    const rounds = await api.get(`/bedroons/${params.id}/${params.round}`);
    localStorage.setItem(`@QuizPremiado:${params.id}`, JSON.stringify(rounds.data[0].bedroom));
  }

  useEffect(() => {
    if (params.round > "1") {
      const bed = localStorage.getItem(`@QuizPremiado:${params.id}`);
      const bedOb = JSON.parse(bed!);
      console.log(bedOb);
      setBedroom({
        round_current: bedOb.round_current,
        round_one: bedOb.round_one,
        round_two: bedOb.round_two,
        round_three: bedOb.round_three,
        round_four: bedOb.round_four,
        status: bedOb.status,
      })
    }
  }, [params.round])

  useEffect(() => {
    loadData();
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 5000)
    }
    if (params.round === '1') {
      if (users1.length >= 16) { //32 lembrar
        setTimes();
        history.push(`/play/${params.id}/${params.round}`)
      }
    }
    if (params.round === '2') {
      console.log(bedroom)
      if (users2.length >= 8) { //16 lembrar
        const aproved = users2.filter(us => us.user.id === user.id);
        console.log(aproved)
        if (aproved.length === 0) {
          history.push(`/lose`);
        }
        if (aproved.length === 1) {
          history.push(`/play/${params.id}/${params.round}`)
        }
      }
      if (new Date() >= new Date(bedroom.round_one)) { finishRound('1') }
    }
    if (params.round === '3') {
      if (users3.length >= 4) {// 8 lembrar
        const aproved = users3.filter(us => us.user.id === user.id);
        if (aproved.length === 0) {
          history.push(`/lose`);
        }
        if (aproved.length === 1) {
          history.push(`/play/${params.id}/${params.round}`)
        }
      }
      if (new Date() >= new Date(bedroom.round_two)) { finishRound('2') }
    }
    if (params.round === '4') {
      if (users4.length >= 2) {// 4 lembrar
        const aproved = users4.filter(us => us.user.id === user.id);
        if (aproved.length === 0) {
          history.push(`/lose`);
        }
        if (aproved.length === 1) {
          history.push(`/play/${params.id}/${params.round}`)
        }
      }
      if (new Date() >= new Date(bedroom.round_three)) { finishRound('3') }
    }
    if (params.round === '5') {
      console.log(cham, vCham)
      if (cham.id !== undefined && vCham.id !== undefined) {
        if (vCham.id === user.id) {
          history.push(`/congratulations/2/${params.price}`);
        }
        if (cham.id === user.id) {
          history.push(`/congratulations/1/${params.price}`);
        }
      }
      if (new Date() >= new Date(bedroom.round_four)) { finishRound('4') }
    }
  }, [time, params.round, user]);

  return (
    <>
      <Paralax />
      <Container>
        <Box>
          <h3>Chaveamento do game</h3>
          <Chaves>

            {(params.round === "1") &&
              < div className="Fase2">
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[0]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[1]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[2]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[3]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[4]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[5]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[6]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[7]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
              </div>
            }
            {(params.round === "1" || params.round === "2") &&
              <div className="Fase3">
                <div className="Key">
                  <div className="User">{(users2.length > 0 && users2[0]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users2.length > 0 && users2[1]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users2.length > 0 && users2[2]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users2.length > 0 && users2[3]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
              </div>
            }
            {(params.round === "1" || params.round === "2" || params.round === "3") &&
              <div className="Fase4">
                <div className="Key">
                  <div className="User">{(users3.length > 0 && users3[0]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users3.length > 0 && users3[1]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
              </div>
            }
            {(params.round === "1" || params.round === "2" || params.round === "3" || params.round === "4" || params.round === "5") &&
              <div className="Fase5">
                <div className='Final'>
                  <ReactSVG src={logo} style={{
                    marginBottom: '1rem',
                    objectFit: "contain"
                  }} />
                  <h4>Grande final</h4>
                </div>
                <div className="User">{(users4.length > 0 && users4[0]?.user.name.toUpperCase()) || "Aguardando"}</div>
                <span>x</span>
                <div className="UserPar">{(users4.length > 0 && users4[1]?.user.name.toUpperCase()) || "Aguardando"}</div>
              </div>
            }
            {(params.round === "1" || params.round === "2" || params.round === "3") &&
              <div className="Fase6">
                <div className="Key">
                  <div className="User">{(users3.length > 0 && users3[2]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users3.length > 0 && users3[3]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
              </div>
            }
            {(params.round === "1" || params.round === "2") &&
              <div className="Fase7">
                <div className="Key">
                  <div className="User">{(users2.length > 0 && users2[4]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users2.length > 0 && users2[5]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users2.length > 0 && users2[6]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users2.length > 0 && users2[7]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
              </div>
            }
            {(params.round === "1") &&
              <div className="Fase8">
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[8]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[9]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[10]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[11]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[12]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[13]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
                <div className="Key">
                  <div className="User">{(users1.length > 0 && users1[14]?.user.name.toUpperCase()) || "Aguardando"}</div>
                  <span>x</span>
                  <div className="UserPar">{(users1.length > 0 && users1[15]?.user.name.toUpperCase()) || "Aguardando"}</div>
                </div>
              </div>
            }

          </Chaves>
        </Box >
      </Container >
    </>
  )
}