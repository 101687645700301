import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #121318;

  @media (max-width: 860px) {
    width: 100%;
    height: auto;
  }
  @media(min-height: 760px){
    height: 100vh;
  }
`;

export const Menu = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #383838;
  margin-bottom: 1rem;
`;

export const ContentMenu = styled.div`
  height: 4rem;
  width: 64rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: relative;

  .Box{
    position: absolute;
    right: 1rem;
    background: #fafafa;
    padding: 1rem;
    border-radius: 5px;
    bottom: -125px;

    button {
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      color: #1F2025;
      margin: 0.5rem 0;
      p {
        font-family: Oswaldr;
        margin-right: 0.5rem;
        color: #1F2025;
      }
    }
    a{
      font-family: Oswaldr;
      color: #1F2025;
      text-decoration: none;
    }
  }
`;

export const Title = styled.div`
  font-family: birds;
  color: #fafafa;
  
`;

export const UserName = styled.button`
  font-family: Oswaldr;
  color: #fafafa;
`;

export const UserInfo = styled.div`
  display: flex;
  button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    margin-left: 1.5rem;
    color: #fafafa;
    p {
      font-family: Oswaldr;
      margin-right: 0.5rem;
      color: #fafafa;
    }}
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  button {
    border: none;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    background-color: #ff9c00;
    font-size: 1.2rem;
    color: #fafafa;
  }
`;

export const ContentModal = styled.div`
  p {
    margin-bottom: 1rem;
    border: 1px solid #c6c6c6;
    padding: 1rem;
    border-radius: 5px;
  }
`;

export const ContentButtonsModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AcceptButton = styled.button`
  width: 10rem;
  margin-right: 1rem;
  padding: 1rem;
  border: none;
  transition: filter 0.3s;
  border-radius: 5px;
  background-color: rgb(63, 180, 63);
  color: #fafafa;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const RefusedButton = styled.button`
  width: 10rem;
  margin-right: 1rem;
  padding: 1rem;
  border: none;
  transition: filter 0.3s;
  border-radius: 5px;
  background-color: rgb(180, 63, 63);
  color: #fff;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-basis: auto;
  max-width: 64rem;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 64rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: flex-start;
  }
`;

export const CardKey = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  background-color: #1F2025;
  color: #fafafa;
  border-radius: 10px;
  margin-bottom: 1rem;
  justify-content: space-between;

  input {
    width: 49%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 1px solid #ff9c00;
    background-color: #ccc;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      color: #fff;
      background: #ff9c00;
      border-radius: 5px;
      border: none;
      padding: 0.6rem;
    }
  }

  @media (max-width: 860px) {
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
  }
`;

export const CardTextKey = styled.h3`
  width: 100%;
  font-family: Oswalds;
  margin-bottom: 1rem;
`;

export const Card1 = styled.div`
  width: 49%;
  padding: 1rem;
  background-color: #1F2025;
  color: #fafafa;
;
  border-radius: 10px;

  @media (max-width: 860px) {
    width: 100%;
    margin-bottom: 1rem;
    height: auto;
  }
`;

export const Profile = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #1F2025;
  color: #fafafa;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  span{
    font-family: Oswalds;
    margin-bottom: 1rem;
    color: #fafafa;
    width: 100%;
    
  }

  div{
    width: 48%;
    display: flex;
    flex-direction: column;

    input{
      padding: 0 0.5rem;
    }

    @media (max-width: 650px) {
      width: 100%;
    }
  }



  .contentOptionKey{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.8rem;

    button{
      width: 23%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 4px 0;
      border: none;
      border-radius: 5px;
      background-color: #ccc;

      svg{
        margin-bottom: 8px;
      }
    }
    button.selected{
      background: #ff9c00;
    }
  }

  label{
    font-family: Oswalds;
    color: #ff9c00;
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }

  input{
    width: 100%;
    margin-bottom: 0.8rem;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ff9c00;
    background-color: #ccc;
  }

  border-radius: 10px;

  @media (max-width: 860px) {
    width: 100%;
    margin-bottom: 1rem;
    height: auto;
  }

  .contentButton{
      display: flex;
      align-items: flex-end;
      width: 48%;
      @media (max-width: 650px) {
          width: 100%;
        }

      button{
        background-color: #ff9c00;
        color: #fafafa;
        border: none;
        height: 40px;
        width:  100%;
        border-radius: 5px;
      }
    }
`;

export const CardTitle = styled.h3`
  font-family: Oswalds;
  margin-bottom: 1rem;
  color: #fafafa;
`;

export const CardInfo = styled.div``;

export const Coins = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 1rem;
  }
  h1 {
    font-size: 1.5rem;
    margin: 0 10px;
  }
  img {
    width: 1.5rem;
  }
`;

export const Balance = styled.div`
  display: flex;
  align-items: center;
  color: #fafafa;
  h3 {
    font-size: 1rem;
  }
  h1 {
    font-size: 1.5rem;
    margin: 0 10px;
  }
`;

export const Card2 = styled.div`
  width: 49%;
  padding: 1rem;
  border-radius: 10px;
  background-color: #1F2025;
  height: 135px;

  @media (max-width: 860px) {
    width: 100%;
    margin-bottom: 1rem;
    height: auto;
  }
`;

export const CardAction = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;

  input {
    width: 70%;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ff9c00;
    background-color: #ccc;
  }

  button {
    color: #fff;
    background: #ff9c00;
    border-radius: 5px;
    border: none;
    padding: 0.6rem;
  }

  @media (max-width: 860px) {
    flex-direction: column;

    input {
      width: 100%;
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`;

export const Plains = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 610px) {
    flex-direction: column;
  }
`;

export const Plan = styled.div`
  margin-right: 1rem;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #121318;

  @media (max-width: 610px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  background: #383838;
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
  h3 {
    color: #fafafa;
    font-family: OswaldB;
    @media (max-width: 800px) {
      text-align: center;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    p {
      font-family: Oswaldb;
      margin-right: 0.5rem;
      font-size: 1.8rem;
      color: #ff9c00;

    }
    img {
      width: 24px;
    }
  }
  button {
    font-family: OswaldR;
    background-color: #ff9c00;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #fff;
    transition: filter 0.3s;
    border: none;

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const Cups = styled.div`
  width: 38%;
  padding: 1rem;
  border-radius: 10px;
  background-color: #1F2025;
  height: 30rem;
  position: relative;
  
  @media (max-width: 860px) {
    margin-bottom: 1rem;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    height: auto;
  }

  .infos{
    color: #fafafa;
    .row{
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      span{
        font-size: 0.8rem
      }
    }
    
    strong{
      color: #ff9c00;
    }
  }

  .ranking{
    height: 50%;
    overflow-y: auto;
    h3{
      color: #fafafa;
    }
    ul{
      list-style: none;
      li{
        background-color: #383838;
        padding: 0.25rem;
        margin-bottom: 0.2rem;
        color: #fafafa; 
        .row{
          display: flex;
          flex-direction: row;
          margin-bottom: 0.5rem;
        }
        strong {
          color: #ff9c00;
          margin-right: 0.5rem
        }
        span{
          margin-right: 0.5rem;
        }
      }
    }
  }
`;

export const Cup = styled.button`
  display: flex;
  width: 100%;
  border: none;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  color: #fff;
  
  margin-bottom: 1rem;
  height: 4rem;
 
  background: linear-gradient(
    152.97deg,
    rgba(16, 66, 230, 0.8) 0%,
    rgba(1, 7, 34, 1) 100%
  );

  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
  font-family: OswaldB;
  transition: background-color 0.3s, border 0.3s, transform 0.3s;

  &:hover {
    background-color: rgba(26, 26, 26, 0.1);
    border: 1px solid #ff9c00;
    transform: translateX(8px);
  }

  img {
    width: 2rem;
  }
`;

export const Buy = styled.div`
  width: 58%;
  height: 30rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: #1F2025;

  @media (max-width: 860px) {
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    height: auto;
  }
`;
