import React from "react";

import {
  Container,
  Content,
  Name,
  Info,
  Merchan,
} from './styles';

import first from '../../assets/primeiro.png';
import second from '../../assets/segundo.png';
import { useParams } from "react-router";
import { Link } from "react-router-dom";

interface ParamProps {
  position: string
  cup?: string
}

export function Congratulations() {
  const { position } = useParams() as ParamProps;
  return (
    <Container>
      <Content>
        <img src={position === '1' ? first : second} alt="congratulations" />
        <Name>Parabéns!!!</Name>
        <Name>{position === '1' ? 'Você acaba de vencer o torneio do EQuiz Premiado!' : 'Dessa vez foi quase, ficamos em segundo!'}</Name>
        <Info>A sua recompensa foi creditada em sua conta e esta disponível para saque.</Info>
        <Merchan>Continue jogando e ganhando! Aqui seu conhecimento vale muito.</Merchan>
        <Link to="/wallet">Voltar para o painel</Link>
      </Content>
    </Container>
  )
}