import React from "react";
import { Switch } from "react-router-dom";
import { About } from "../pages/About";

import Bedroon from "../pages/Bedroon";
import CheckCode from "../pages/CheckCode";
import { Congratulations } from "../pages/Congratulations";
import { Faqs } from "../pages/Faqs";
import Home from "../pages/Home";
import { Info } from "../pages/Info";
import { Lose } from "../pages/Lose";
import Play from "../pages/Play";
import PlayGame from "../pages/PlayGame";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import RecoveryPass from "../pages/RecoveryPass";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import UpdatePass from "../pages/UpdatePass";
import { Usage } from "../pages/Usage";
import Wallet from "../pages/Wallet";
import Route from "./Route";


const Routes: React.FC = () => (
  <Switch>
    <Route path='/' exact component={Home} />
    <Route path='/privacyPolicy' component={PrivacyPolicy} />
    <Route path='/faqs' component={Faqs} />
    <Route path='/congratulations/:position/:cup' isPrivate component={Congratulations} />
    <Route path='/signin' component={SignIn} />
    <Route path='/signup' component={SignUp} />
    <Route path='/about' component={About} />
    <Route path='/usage' component={Usage} />
    <Route path='/recoverypass' component={RecoveryPass} />
    <Route path='/checkcode/:phone/:email' component={CheckCode} />
    <Route path='/updatepass/:phone/:email' component={UpdatePass} />
    <Route path='/wallet' isPrivate component={Wallet} />
    <Route path='/lose' isPrivate component={Lose} />
    <Route path='/bedroon/:id/:round' isPrivate component={Bedroon} />
    <Route path='/play/:id/:round' isPrivate component={Play} />
    <Route path='/playgame' isPrivate component={PlayGame} />
    <Route path='/info/:q/:time' isPrivate component={Info} />
  </Switch>
)

export default Routes;