import styled, { css } from "styled-components";


interface OptionProps {
  isActive?: boolean;
}

export const Paralax = styled.div`
  display: flex;
  background: #121318;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  height: 100vh;
  background-color: rgba(26, 26, 26, 0.87);
  padding: 2rem;
`;

export const BoxGame = styled.div`
  position: relative;
  display: flex;
  max-width: 60rem;
  width: 100%;
  height: 80vh;
  padding: 1rem 2rem;
  background:  #1F2025;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  .Mapquestion {
    min-width: 100%;
    width: 100%;
  }

  .Quest {
    width: 100%;
    min-width: 100%;
    border: 5px solid red;
    border-radius: 20px;
    padding: 1rem;
  }

  .Question {
    width: 100%;
    padding: 1rem 0;
  }

  .Response {
    width: 100%;
    padding: 1rem 0;
  }

  button.rec{
    background-color: transparent;
  }

  button.rec-arrow{
    color: #ff9c00;
  }

  button.rec:hover{
    background-color: #ff9c00;
  }
`;

export const Aviso = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  margin-top: -1rem;
  
  p {
    font-size: 1rem;
    color: #fafafa
  }
`;

export const BoxQuestion = styled.div`
  border: 5px solid #ccc;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ccc;
`;

export const Question = styled.div`
  color: #121318;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Count = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  h4 {
    font-family: OswaldS;
    font-size: 1.5rem;
    color: #ff9c00;
  }
  p {
    font-size: 1.5rem;
    color: #ff9c00;
    padding: 10px;
  }
`;

export const Option = styled.button<OptionProps>`
  width: 50%;
  padding: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ isActive }) =>
    isActive
      ? css`linear-gradient(180deg, #ff9c00 0%, #D98500 19.27%, #D98500 85.94%, #ff9c00 100%)`
      : css`linear-gradient(180deg, #888 0%, #333 19.27%, #333 85.94%, #888 100%)`};
  margin-bottom: 0.5rem;
  border-radius: 30px;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.8);
  }

  label {
    margin-left: 0.5rem;
    color: #fff;
  }
`;

export const Button = styled.button`
  background-color: rgb(0, 0, 250);
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  margin-bottom: 2rem;

  &:hover {
    filter: brightness(0.9);
  }
`;
