import React, { useState, useEffect } from "react";
import { motion, useViewportScroll } from "framer-motion";

import {
  Paralax,
  Container,
  ContentMenu,
  Menu,
  MenuMobile,
  Banner,
  BanMobile,
  AnimeBan,
  Title,
  Store,
  Button,
  Content,
  ContentThree,
  ProgressBar,
  Count,
  ButtonCup,
  ContentTime,
  ButtonContent,
  ContentCups,

  Copyright,
} from "./styles";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

// import logo from '../../assets/logo.png';
import ban1 from '../../assets/banner01.jpg';
import ban2 from '../../assets/banner02.jpg';
import love from '../../assets/loove.png';
import money from '../../assets/money.png';
// import first from '../../assets/section2-1.jpg';
// import gamer from '../../assets/gamer.png';
import app from '../../assets/app-game.png';
// import one from '../../assets/1.png';
import five from '../../assets/10.png';
import ten from '../../assets/20.png';
import twenty from '../../assets/40.png';
import fifty from '../../assets/100.png';
import section2 from '../../assets/section2-1.jpg';
import google from '../../assets/google.png';
// import apple from '../../assets/apple.png';
import { Footer } from "../../components/Footer";


export default function Home() {
  const history = useHistory();

  const [widthWindow, setWidthWindow] = useState(1366);


  const [ban, setBan] = useState(false)
  const [widthBan, setWidthBan] = useState(140);
  const [timeBan, setTimeBan] = useState(800);

  const [cfive, setCfive] = useState(26 * 60 * 60);
  const [cten, setCten] = useState(18 * 60 * 60);
  const [ctwenty, setCtwenty] = useState(19 * 60 * 60);
  const [cfifty, setCfifty] = useState(20 * 60 * 60);

  // const hour = new Date().getHours();


  // const [time, setTime] = useState(25 * 60);
  // const [active, setActive] = useState(false);

  // const cfivehours = Math.floor((cfive / 60) / 60)
  // const cfiveminutes = Math.floor((cfive / 60) % 60);
  // const cfiveseconds = cfive % 60;

  // const [cfivehourLeft, cfivehourRight] = String(cfivehours).padStart(2, '0').split('');
  // const [cfiveminuteLeft, cfiveminuteRight] = String(cfiveminutes).padStart(2, '0').split('');
  // const [cfivesecondLeft, cfivesecondRight] = String(cfiveseconds).padStart(2, '0').split('');

  // const ctenhours = Math.floor((cten / 60) / 60)
  // const ctenminutes = Math.floor((cten / 60) % 60);
  // const ctenseconds = cten % 60;

  // const [ctenhourLeft, ctenhourRight] = String(ctenhours).padStart(2, '0').split('');
  // const [ctenminuteLeft, ctenminuteRight] = String(ctenminutes).padStart(2, '0').split('');
  // const [ctensecondLeft, ctensecondRight] = String(ctenseconds).padStart(2, '0').split('');

  // const ctwentyhours = Math.floor((ctwenty / 60) / 60)
  // const ctwentyminutes = Math.floor((ctwenty / 60) % 60);
  // const ctwentyseconds = ctwenty % 60;

  // const [ctwentyhourLeft, ctwentyhourRight] = String(ctwentyhours).padStart(2, '0').split('');
  // const [ctwentyminuteLeft, ctwentyminuteRight] = String(ctwentyminutes).padStart(2, '0').split('');
  // const [ctwentysecondLeft, ctwentysecondRight] = String(ctwentyseconds).padStart(2, '0').split('');

  // const cfiftyhours = Math.floor((cfifty / 60) / 60)
  // const cfiftyminutes = Math.floor((cfifty / 60) % 60);
  // const cfiftyseconds = cfifty % 60;

  // const [cfiftyhourLeft, cfiftyhourRight] = String(cfiftyhours).padStart(2, '0').split('');
  // const [cfiftyminuteLeft, cfiftyminuteRight] = String(cfiftyminutes).padStart(2, '0').split('');
  // const [cfiftysecondLeft, cfiftysecondRight] = String(cfiftyseconds).padStart(2, '0').split('');

  useEffect(() => {
    if (cfive > 0) {
      setTimeout(() => {
        setCfive(cfive - 1);
      }, 1000)
    }
  }, [cfive]);

  useEffect(() => {
    if (cten > 0) {
      setTimeout(() => {
        setCten(cten - 1);
      }, 1000)
    }
  }, [cten]);

  useEffect(() => {
    if (ctwenty > 0) {
      setTimeout(() => {
        setCtwenty(ctwenty - 1);
      }, 1000)
    }
  }, [ctwenty]);

  useEffect(() => {
    if (cfifty > 0) {
      setTimeout(() => {
        setCfifty(cfifty - 1);
      }, 1000)
    }
  }, [cfifty]);


  const { scrollY } = useViewportScroll();

  useEffect(() => {
    setWidthWindow(window.innerWidth)
  }, [scrollY])


  // function startCountdown() {
  //   setActive(true);
  // }

  // useEffect(() => {
  //   if (active && time > 0) {
  //     setTimeout(() => {
  //       setTime(time - 1);
  //     }, 1000)
  //   }
  // }, [active, time]);

  useEffect(() => {
    if (timeBan > 0) {
      setTimeout(() => {
        setTimeBan(timeBan - 1)
        setWidthBan(Number(widthBan.toFixed(2)) - 0.05);
      }, 1)
    }
    if (timeBan === 0) {
      setTimeBan(800)
      setWidthBan(140)
      setBan(!ban)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeBan])


  return (
    <>
      <Paralax />
      <Container >
        <ContentMenu>
          <MenuMobile >
            <ul>
              <li>
                <Link to="/signin">Minha Conta</Link>
              </li>
            </ul>
          </MenuMobile>
          <Menu >
            <ul>
              <li><Link to="/" className="active">Home</Link></li>
              <li>
                <Link to="/signin">Minha Conta</Link>
              </li>
              <li>
                <Link to="/signup">Cadastre-se</Link></li>
            </ul>
          </Menu>
        </ContentMenu>
        <Banner >
          <AnimeBan>
            {ban && <img src={ban1} alt='banner1' style={{
              width: `${widthBan}%`,
              objectFit: "cover"
            }} />
            }
            {!ban && <img src={ban2} alt='banner1' style={{
              width: `${widthBan}%`
            }} />
            }
          </AnimeBan>
          <BanMobile src={ban1} alt='banner1' />
          <div>
            {/* <img id="logo" src={logo} alt="imagem teste" /> */}
            <div id="infoBan">
              <h3>GAME OF PRICES</h3>
              <Title>EQUIZ</Title>
              <Title>PREMIADO</Title>
              <div className="mark" />
              <p>ESTAMOS EM MANUTENÇÃO</p>
              {/* <p>UMA NOVA DIVERSÃO</p> */}
              <Button onClick={() => history.push('/wallet')}>CONHEÇA</Button>
            </div>
          </div>
        </Banner>
        <section id="one">
          <h3>SOMENTE OS MELHORES IRÃO VENCER</h3>
          <h1>O QUE TE MOTIVA?</h1>
          <div className="mark" />
          <Content >
            <div className="disImg">
              <img className="imgesq" src={love} alt="imagem teste" width="220px" />
              <h3>PAIXÃO POR JOGOS</h3>
            </div>
            <h1>VS</h1>
            <div className="disImg">
              <img className="imgdir" src={money} alt="imagem teste" width="160px" />
              <h3>PRÊMIOS EM DINHEIRO</h3>
            </div>
          </Content>
        </section>
        <div className="Divisor">
          <h2>AQUI UNIMOS OS DOIS EM UM SÓ LUGAR</h2>
        </div>
        <section id="two">
          <Content >
            <div className="imageSectionTwo">
              <img src={section2} alt="imagem teste" />
            </div>


            <div className="textSectionTwo">
              <h3>ENTENDA COMO FUNCIONA</h3>
              <h1>EQUIZ PREMIADO</h1>
              <div className="mark" />

              <p>Copas online 24h, para você se divertir usando seu conhecimento e ganhar dinheiro!</p>
              <p>Quem ficar melhor qualificado ganha MAIOR PRÊMIO EM DINHEIRO!!</p>
              <label>1° LUGAR - Prêmio mínimo!</label>
              <ProgressBar >
                {
                  scrollY.get() > ((widthWindow * 3) / 4) &&
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: '100%' }}
                    transition={{ duration: 2 }}
                    style={{ width: '100%', overflow: 'hidden' }}
                  >
                    <span style={{ maxLines: 1 }} >
                      5 VEZES MAIS
                    </span>
                  </motion.div>
                }
              </ProgressBar>
              <label>2° LUGAR - Prêmino mínimo</label>
              <ProgressBar >
                {
                  scrollY.get() > ((widthWindow * 3) / 4) &&
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: '40%' }}
                    transition={{ duration: 2 }}
                    style={{ width: '100%', overflow: 'hidden' }}
                  >
                    <span style={{ maxLines: 1 }} >
                      O VALOR DA ENTRADA
                    </span>
                  </motion.div>
                }
              </ProgressBar>
              <Button onClick={() => history.push('/wallet')}>JOGUE AGORA</Button>
            </div>
          </Content>
        </section>
        <div className="sectionThree">
          <ContentThree>
            <div className="imageSectionThree">
              <img src={app} alt="imagem teste" />
            </div>
            <div className="textSectionThree">
              <h3>TESTE SEU CONHECIMENTO</h3>
              <h1>BAIXE NOSSO APP</h1>
              <div className="mark" />
              <Store>
                <a href="https://play.google.com/store/apps/details?id=br.com.quizpremiadoApp" target="_blank" rel="noopener noreferrer">
                  <img src={google} alt="google" />
                </a>
                {/* <a href="/home">
                  <img src={apple} alt="apple" />
                </a> */}
              </Store>
              <p>Milhares de gamer’s já estão ganhado competições e garantindo seus prêmios em dinheiro.</p>
              <p>Não fique de fora, baixe já nosso aplicativo e entre nessa diversão!!</p>

              <div className="Counters">
                <Count >
                  <h2>
                    1.300+
                  </h2>
                  <span>DOWNLOADS</span>
                </Count>
                <Count >
                  <h2>500+</h2>
                  <span>GANHADORES</span>
                </Count>
                <Count >
                  <h2>500+</h2>
                  <span>PARTIDAS</span>
                </Count>
              </div>
            </div>
          </ContentThree>
        </div>
        <section id="four">
          <h3>VEJA AS PRÓXIMAS PARTIDAS</h3>
          <h1>ESCOLHA UMA COPA E PARTICIPE</h1>
          <div className="mark" />
          <ContentCups>
            <ButtonCup onClick={() => history.push('/wallet')}>
              <div>
                <h3>PARTIDAS FIVE - 5 fichas</h3>
                <ContentTime >
                  <div><h1>EM BREVE</h1></div>
                  {/* <div>
                    <h1>{cfivehourLeft}{cfivehourRight}</h1>
                    <p>Horas</p>
                  </div>
                  <div>
                    <h1>{cfiveminuteLeft}{cfiveminuteRight}</h1>
                    <p>Minutos</p>
                  </div>
                  <div>
                    <h1>{cfivesecondLeft}{cfivesecondRight}</h1>
                    <p>Segundos</p>
                  </div> */}
                </ContentTime>
              </div>
              <img src={five} alt="imagem teste" />

            </ButtonCup >
            <hr />
            <ButtonCup onClick={() => history.push('/wallet')}>
              <div>
                <h3>PARTIDAS TEN - 10 fichas</h3>
                <ContentTime>
                  <div><h1>EM BREVE</h1></div>
                  {/* <div>
                    <h1>{ctenhourLeft}{ctenhourRight}</h1>
                    <p>Horas</p>
                  </div>
                  <div>
                    <h1>{ctenminuteLeft}{ctenminuteRight}</h1>
                    <p>Minutos</p>
                  </div>
                  <div>
                    <h1>{ctensecondLeft}{ctensecondRight}</h1>
                    <p>Segundos</p>
                  </div> */}
                </ContentTime>
              </div>
              <img src={ten} alt="imagem teste" />

            </ButtonCup >
            <hr />
            <ButtonCup onClick={() => history.push('/wallet')}>
              <div>
                <h3>PARTIDAS TWENTY - 20 fichas</h3>
                <ContentTime>
                  <div><h1>EM BREVE</h1></div>
                  {/* <div>
                    <h1>{ctwentyhourLeft}{ctwentyhourRight}</h1>
                    <p>Horas</p>
                  </div>
                  <div>
                    <h1>{ctwentyminuteLeft}{ctwentyminuteRight}</h1>
                    <p>Minutos</p>
                  </div>
                  <div>
                    <h1>{ctwentysecondLeft}{ctwentysecondRight}</h1>
                    <p>Segundos</p>
                  </div> */}
                </ContentTime>
              </div>
              <img src={twenty} alt="imagem teste" />

            </ButtonCup>
            <hr />
            <ButtonCup onClick={() => history.push('/wallet')}>
              <div>
                <h3>PARTIDAS FIFTY - 50 fichas</h3>
                <ContentTime>
                  <div><h1>EM BREVE</h1></div>
                  {/* <div>
                    <h1>{cfiftyhourLeft}{cfiftyhourRight}</h1>
                    <p>Horas</p>
                  </div>
                  <div>
                    <h1>{cfiftyminuteLeft}{cfiftyminuteRight}</h1>
                    <p>Minutos</p>
                  </div>
                  <div>
                    <h1>{cfiftysecondLeft}{cfiftysecondRight}</h1>
                    <p>Segundos</p>
                  </div> */}
                </ContentTime>
              </div>
              <img src={fifty} alt="imagem teste" />

            </ButtonCup >
            <ButtonContent>
              <Button onClick={() => history.push('/privacyPolicy')}>VER REGRAS</Button>
              <Button onClick={() => history.push('/wallet')}>JOGUE AGORA</Button>
            </ButtonContent>
          </ContentCups>
        </section>

        {/* <section id="five">
          <h3>FINALISTAS</h3>
          <h1>VEJA OS MAIORES CAMPEÕES</h1>
          <div className="mark" />
          <ContentCards>
            <CardPlay>
              <img src={gamer} alt="imagem teste" />
              <h3>PAIXÃO POR JOGOS</h3>
              <p></p>
            </CardPlay>
            <CardPlay>
              <img src={gamer} alt="imagem teste" />
              <h3>PRÊMIOS EM DINHEIRO</h3>
            </CardPlay>
            <CardPlay>
              <img src={gamer} alt="imagem teste" />
              <h3>PAIXÃO POR JOGOS</h3>
              <p></p>
            </CardPlay>
            <CardPlay>
              <img src={gamer} alt="imagem teste" />
              <h3>PRÊMIOS EM DINHEIRO</h3>
            </CardPlay>
          </ContentCards>
        </section> */}
      </Container >
      <Footer />
      <Copyright>
        <p>Copyright © Todos os direitos reservados</p>
      </Copyright>
    </>
  );
}
