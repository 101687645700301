import styled from "styled-components";


export const Container = styled.div`
background: #121111;
display: flex;
justify-content: center;
`;

export const ContentFooter = styled.div`
display: flex;
max-width: 70rem;
padding: 2rem;
width: 100%;
justify-content: space-between;

@media (max-width: 1000px) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
`;

export const Logo = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex: 1;
padding-right: 1rem;
width: 30%;

@media (max-width: 1000px) {
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  flex-wrap: wrap;
}

img {
  width: 200px;
  @media (max-width: 800px) {
    width: 150px;
  }
}

#address {
  margin: 1rem 0;
  p {
    color: #fff;
    font-family: OswaldL;
    font-size: 1.2rem;
  }
}

ul {
  li {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    svg {
      margin-right: 1rem;
      color: #ff9c00;
    }

    a {
      text-decoration: none;
      color: #fff;
      transition: color 0.3s;
      font-family: OswaldL;
      font-size: 1.2rem;

      &:hover {
        color: #ff9c00;
      }
    }
  }
}
`;

export const Contact = styled.div`
display: flex;
width: 30%;
h3 {
  font-family: OswaldS;
  font-size: 2rem;
  color: #ff9c00;
  font-style: italic;
  margin-bottom: 1rem;
  @media (max-width: 800px) {
    text-align: left;
  }
}

@media (max-width: 1000px) {
  width: 100%;
  text-align: center;
}

ul {
  li {
    list-style: none;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #fff;
      font-family: OswaldR;
      font-size: 1.2rem;
      transition: color 0.3s;

      &:hover {
        color: #ff9c00;
      }
    }

    svg {
      color: #ff9c00;
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 1000px) {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    li {
      padding-right: 2rem;
    }
  }

  @media (max-width: 550px) {
    display: block;
  }
}
`;

export const Copyright = styled.div`
display: flex;
background-color: #000;
color: #ff9c00;
align-items: center;
justify-content: center;
padding: 1rem;
`;