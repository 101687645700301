import React, { FormEvent, useState, } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { Paralax, Container, BoxLogin, Form } from "./styles"

interface ParamsProps {
  phone: string;
  email: string;
}



export default function UpdatePass() {
  const [pass, setPass] = useState('')
  const [cpass, setCPass] = useState('')
  const { addToast } = useToast();
  const history = useHistory();

  const { phone, email } = useParams() as ParamsProps;


  async function handleRecovery(event: FormEvent) {
    event.preventDefault();

    try {
      if(pass === cpass){
        api.post('/updatepass',{
          phone, email, pass
        }).then(res => {
          history.push(`/signin`);
        })
      }
      else{
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Senhas divergentes, tente novamente!',
        });
      }
    } catch (err) {

      addToast({
        type: 'error',
        title: 'Erro na requisição',
        description: 'Código inválido',
      });
    }
  }


  return (
    <>
      <Paralax />
      <Container >
        <BoxLogin >
          <h3>Digite sua nova senha</h3>
          <Form onSubmit={handleRecovery} >
            <input type="password" placeholder="Senha" name="email" id="pass" value={pass} onChange={e => setPass(e.target.value)} />
            <input type="password" placeholder="Confirme a senha" name="email" id="pass" value={cpass} onChange={e => setCPass(e.target.value)} />
            <button type="submit">Enviar</button>
          </Form>

          <Link to="/signup"><h4>Criar conta</h4></Link>
          <Link to="/signin"><h6>Já tenho conta</h6></Link>
        </BoxLogin>
      </Container>
    </>
  )
}