/* eslint-disable camelcase */
import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import api from '../services/api';
import {io, Socket} from "socket.io-client";

interface UserData {
  name: string;
  email: string;
  id: string;
  phone: string;
  balance: number;
  coins: number;
  pix_key: string;
  type_pix_key: 'key'|'mail'|'doc'|'phone';
  document?: string;
}

interface AuthState {
  token: string;
  user: UserData;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: UserData;
  signIn(credetionals: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: UserData): void;
  socket: Socket;
  con: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [socket, setSocket] = useState<Socket>({} as Socket);
  const [con, setCon] = useState(false);

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@QuizPremiado:token');
    const user = localStorage.getItem('@QuizPremiado:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  useEffect(() => {
    const conn = io("https://api.equizpremiado.com.br", {
      transports: ['websocket'], 
      upgrade: false
    });
    conn.on("connect", () => {
      setCon(true);
  })
    setSocket(conn);
},[]);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@QuizPremiado:token', token);
    localStorage.setItem('@QuizPremiado:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@QuizPremiado:token');
    localStorage.removeItem('@QuizPremiado:user');

    setData({} as AuthState);

    history.push("/");

  }, [history]);

  const updateUser = useCallback(
    (user: UserData) => {
      localStorage.setItem('@QuizPremiado:user', JSON.stringify(user));

      setData({ token: data.token, user });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser, socket, con}}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
