import styled from "styled-components";

export const Paralax = styled.div`
  display: flex;
  background: #121318;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  @media (max-width: 800px) {
    height: 150vh;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;

  background-color: rgba(26, 26, 26, 0.87);
`;

export const Content = styled.div`
  display: flex;
  max-width: 70rem;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  background:  #1F2025;
  color: #fafafa;
  padding: 2rem 1rem;
  border-radius: 20px;

  h3,h2,h4,
  p {
    margin-bottom: 1rem;
  }
  p {
    text-align: justify;
    line-height: 24px;
    font-size: 1.2rem;
  }

  a {
    text-decoration: none;
    padding: 0.5rem 2rem;
    background-color: #ff9c00;
    color: #fff;
    font-family: OswaldS;
    font-size: 1.5rem;
    font-style: italic;
    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  ul {
    list-style: disc;
    li{
      margin-left: 2rem;
    }
  }
  
  .Contato{
    padding: 1rem;
    background-color: #383838;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
`;
