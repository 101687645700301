import React from "react";
import { Footer } from "../../components/Footer";

import { Container, Content, Paralax } from './styles';

export function PrivacyPolicy() {
  return (
    <>
      <Paralax />
      <Container >
        <Content>
          <h2 id="topo">Política de Privacidade?</h2>
          <p>No Game Of Prices – EQuiz Premiado, privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários. Por isso, a presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de usuários que acessam nosso site.</p>
          <p>Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas formas descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento JURÍDICO BRASILEIRO APLICÁVEIS.</p>
          <p>Dessa forma, a empresa Ferreira Entretenimento e E-commerce Ltda doravante denominada simplesmente como “GAME OF PRICES” inscrita no CNPJ sob o nº42.379.976/0001-85, no papel de Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.</p>
          <h3>1. Quais dados coletamos sobre você e para qual finalidade?</h3>
          <p>Nosso site coleta alguns dados pessoais, de forma a viabilizar a prestação de serviços, segurança e aprimorar a experiência de uso.</p>
          <h4>Dados pessoais que serão fornecidos pelo usuário:</h4>
          <ul>
            <li>
              <p>Nome completo</p>
            </li>
            <li>
              <p>Endereço atual completo</p>
            </li>
            <li>
              <p>Telefones de contatos</p>
            </li>
            <li>
              <p>E-mail vigente</p>
            </li>
            <li>
              <p>CPF</p>
            </li>
            <li>
              <p>Chave PIX</p>
            </li>
          </ul>
          <h4>1.1 Como coletamos os seus dados?</h4>
          <p>A coleta dos seus dados pessoais ocorre da seguinte forma: Os dados deverão ser informados pelo usuário no momento do cadastro, pois a plataforma não irá coletar nenhum dado de forma automática.</p>
          <h4>1.2 Consentimento</h4>
          <p>É a partir do seu consentimento que trabalharemos com seus dados pessoais (utiliza-los para verificação de autenticidade, envio de cartas, e-mails entre outros). O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza o GAME OF PRICES a tratar seus dados. Ou seja, a mesma é consentida através do ACEITE no momento do cadastro.</p>
          <p>Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento. </p>
          <p>O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o compromisso de transparência e boa-fé da GAME OF PRICES para com seus usuários, seguindo os regulamentos legislativos pertinentes.</p>
          <p>A qualquer momento e sem nenhum custo, você poderá revogar seu consentimento e cancelar o cadastro no site, desta forma todo acesso será de imediato cancelado. E nenhum valor em fichas que possivelmente tenha adquirido será ressarcido. </p>
          <p>É importante destacar que a revogação do consentimento para o tratamento dos dados poderá implicar na impossibilidade da performance adequada de alguma funcionalidade do site que dependa da operação. Tais consequências serão informadas previamente.</p>
          <h4> Quais são os seus direitos?</h4>
          <p>A GAME OF PRICES assegura a seus usuários os direitos de titular previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:</p>
          <ul>
            <li>
              <p>Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.</p>
            </li>
            <li>
              <p>Acessar seus dados, podendo a todo o momento monitora-los através do portal do usuário.</p>
            </li>
            <li>
              <p>Corrigir seus dados, corrigir e/ou atualizá-los através do portal do usuário.</p>
            </li>
            <li>
              <p>Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através do anonimato, bloqueio e/ou eliminação.</p>
            </li>
            <li>
              <p>Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.</p>
            </li>
            <li>
              <p>Revogar seu consentimento, desautorizando o tratamento de seus dados e cancelando o cadastro no site.</p>
            </li>
          </ul>
          <h4>Como você pode exercer seus direitos de titular?</h4>
          <p>Para exercer seus direitos de titular, você deve acessar o portal do usuário dentro do site ou entrar em contato com a GAME OF PRICES através dos seguintes meios disponíveis:</p>
          <div className="Contato">
            <p><strong>E-mail: </strong>atendimento@equizpremiado.com.br</p>
            <p><strong>Telefone: </strong>(64) 98170-6160</p>
            <p><strong>WhatsApp: </strong>(64) 98170-6160</p>
          </div>
          <h3>2. Como e por quanto tempo seus dados serão armazenados?</h3>
          <p>Os dados pessoais informados pelos usuários no momento do cadastro permanecerão no banco de dados do site até momento que o usuário realizar o cancelamento do cadastro no portal do usuário do site GAME OF PRICES ou caso a empresa verifique alguma fraude no cadastro e assim suspender o acesso e pós 72h cancelar o usuário suspeito. Ação que será realizada sem aviso prévio ao usuário. Cabendo ao usuário o compromisso de entrar em contato através do e-mail atendimento@equizpreiado.com.br</p>
          <p>De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a GAME OF PRICES perdurar. Findado o período de armazenamento dos dados pessoais, estes serão PERMANENTEMENTE excluídos de nossas bases de dados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados, a saber:</p>
          <ul>
            <li>
              <p>Cumprimento de obrigação legal ou regulatória pelo controlador;</p>
            </li>
            <li>
              <p>Estudo por órgão de pesquisa, garantida, sempre que possível, o anonimato dos dados pessoais;</p>
            </li>
            <li>
              <p>Transferência à terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei, ou</p>
            </li>
            <li>
              <p>Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que os dados sejam em anonimato.</p>
            </li>
          </ul>
          <p>Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados. </p>
          <p>O armazenamento de dados coletados pela GAME OF PRICES reflete o nosso compromisso com a segurança e privacidade dos seus dados.Empregamos medidas e soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados.Além disso, também contamos com medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.</p>

          <h3>3. O que fazemos para manter seus dados seguros ?</h3>
          <p>Para mantermos suas informações pessoais seguras, usamos ferramentas eletrônicas e gerenciais orientadas para a proteção da sua privacidade.</p>

          <p>Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.</p>
          <p>Entre as medidas que adotamos, destacamos as seguintes:</p>
          <ul>
            <li><p>Apenas pessoas autorizadas têm acesso a seus dados pessoais</p></li>
            <li><p>O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade</p></li>
            <li><p>Seus dados pessoais são armazenados em ambiente seguro e idôneo.</p></li>
          </ul>
          <p>O GAME OF PRICES compromete a adotar as melhores posturas para evitar incidentes de segurança.Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos.É possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário.</p>
          <p>Em caso de incidentes de segurança que possa gerar risco ou dano relevante para o usuário, comunicaremos ao afetado e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.</p>

          <h3>4.  Com quem seus dados podem ser compartilhados?</h3>
          <p>Tendo em vista a preservação de sua privacidade, a GAME OF PRICES não compartilhará seus dados pessoais com nenhum terceiro não autorizado.</p>
          <p>Todavia, nossos parceiros têm suas próprias Políticas de Privacidade, que podem divergir desta. </p>
          <p>Além disso, também existem outras hipóteses em que seus dados poderão ser compartilhados, que são:</p>
          <ul>
            <li><p>Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes.</p></li>
            <li><p>Proteção dos direitos do GAME OF PRICES em qualquer tipo de conflito, inclusive os de teor judicial.</p></li>
          </ul>
          <h3>5. Transferência internacional de dados</h3>
          <p>Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou possuir instalações localizadas em países estrangeiros.Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras de proteção de dados.</p>
          <p>Nesse sentido, o GAME OF PRICES se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas.</p>
          <h3>6.  Cookies ou dados de navegação</h3>
          <p>O GAME OF PRICES faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site.Em suma, os Cookies são utilizados para aprimorar a experiência de uso.</p>
          <p>Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.</p>

          <p>O GAME OF PRICES – EQUIZ PREMIADO utiliza apenas Cookies de sessão. (incluir os que é verdadeiramente usado)</p>
          <h3>7.  Alteração desta Política de Privacidade</h3>
          <p>A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: (13 / 07 / 2022).</p>
          <p>Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, principalmente em função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. </p>

          <p>Recomendamos que você a revise com frequência.</p>

          <p>Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você as consente.</p>

          <h3>8. Responsabilidade</h3>
          <p>O GAME OF PRICES – EQUIZ PREMIADO prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.</p>
          <p>Nos comprometemos a manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu cumprimento.</p>
          <p>Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.</p>
          <p>Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de dados realizado pela GAME OF PRICES – EQUIZ PREMIADO, comprometemo - nos a segui - las. </p>

          <h3>9. Isenção de responsabilidade</h3>
          <p>Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos.Nesse sentido, o GAME OF PRICES – EQUIZ PREMIADO não se responsabiliza por:</p>
          <ul>
            <li><p>Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais.Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento.</p></li>
            <li><p>Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.</p></li>
            <li><p>Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada do GAME OF PRICES.</p></li>
            <li><p>Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários / clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.</p></li>
            <li><p>Inveracidade das informações inseridas pelo usuário / cliente nos registros necessários para a utilização dos serviços do GAME OF PRICES; quaisquer consequências decorrentes de informações falsas ou inseridas de má - fé são de inteiramente responsabilidade do usuário / cliente.</p></li>
          </ul>
          <h3>10.  Encarregado de Proteção de Dados</h3>
          <p>O GAME OF PRICES disponibiliza os seguintes meios para que você possa entrar em contato conosco para exercer seus direitos de titular e ou confirmar dados da empresa: </p>
          <div className="Contato">
            <p><strong>E-mail: </strong>atendimento@equizpremiado.com.br</p>
            <p><strong>Telefone: </strong>(64) 98170-6160</p>
            <p><strong>WhatsApp: </strong>(64) 98170-6160</p>
          </div>
          <p>Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em contato através do e - mail: atendimento @equizpremiado.com.br  a dúvida será encaminhada para os responsáveis, com o prazo de 72 horas o usuário será respondido e / ou informado da atual situação.</p>
        </Content>
      </Container>
      <Footer />
    </>
  )
}








