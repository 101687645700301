import React, { useCallback, useState, useEffect } from 'react';

import { GoSignOut, GoKey, GoMail } from 'react-icons/go';
import { MdPhoneIphone } from 'react-icons/md';
import { HiIdentification } from 'react-icons/hi';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router';
import Modal from 'react-modal';


import {
  Container,
  Menu,
  ContentMenu,
  ModalHeader,
  ContentModal,
  ContentButtonsModal,
  AcceptButton,
  RefusedButton,
  Title,
  UserName,
  UserInfo,
  Content,
  Wrapper,
  Card1,
  CardTitle,
  CardInfo,
  Coins,
  Balance,
  Card2,
  CardAction,
  Plains,
  Plan,
  Cups,
  Cup,
  Buy,
  Profile
} from './styles';

import money from "../../assets/money.png"
import { useToast } from '../../hooks/toast';
import { maskPrice } from '../../utils/masks';

// interface CupsProps = {
//   id: string;
//   description: string;
//   award: number;
//   price: number;
//   active: number;
//   dataActive: Date;
// }

interface User {
  quantity: number;
  time: number;
  user: {
    name: string
  }
}

interface RankingProps {
  bedroom: {
    champion_value: string;
    price: string;
    second_value: string;
  }
  quantity: number;
  users: User[];
}

export default function Wallet() {
  const history = useHistory();
  const { user, signOut, updateUser } = useAuth();
  // const [cupAction, setCupAction] = useState('');
  const [valueAction, setValueAction] = useState(0);
  // const [cups, setCups] = useState<CupsProps[]>();
  const [completeRegister, setCompleteRegister] = useState(false);
  const [cashOut, setCashOut] = useState('');
  const [document, setDocument] = useState(user.document ? user.document : '')
  const [phone, setPhone] = useState(user.phone ? user.phone : '')
  const [pix_key, setPix_key] = useState(user.pix_key ? user.pix_key : '')
  const [data, setData] = useState<RankingProps>({} as RankingProps);
  const [type_pix_key, setType_pix_key] = useState<'key' | 'mail' | 'doc' | 'phone'>(user.type_pix_key ? user.type_pix_key : 'doc')
  const { addToast } = useToast();
  const { socket, con } = useAuth();

  const [carteira, setCarteira] = useState(true);
  const [perfil, setPerfil] = useState(false);
  const [openOption, setOpenOption] = useState(false);



  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(value: number) {
    setIsOpen(true);
    // setCupAction(cup);
    setValueAction(value);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (con) {
      socket.on('ranking', (d) => {
        setData(d)
      })
      socket.emit('novaResposta')
    }
  }, [con, socket])



  // async function loadCups() {
  //   const response = await api.get('/cups');

  //   if (response.data) {
  //     setCups(response.data);
  //   }
  // }


  const handleSubmitCup = useCallback(async (price: number) => {
    if (user.coins >= price) {
      history.push(`/playgame`);
    } else {
      addToast({
        type: 'error',
        title: 'Erro na transação',
        description: 'Coins insuficiente para participar da copa!',
      });
      return
    }
  }, [user, history, addToast]);

  async function loadUser() {
    await api.get('/users/me').then(res => {
      if (res.status === 201) {
        updateUser(res.data)
      } else {
        signOut()
      }
    }).catch(e => signOut())
  }


  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleCredit(val: number) {
    try {
      const response = await api.post('/transactions/init', { value: val })
      window.location.replace(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleAppointment(value: string) {
    if (user.balance < Number(value)) {
      addToast({
        type: 'error',
        title: 'Erro na transação',
        description: 'Saldo insuficiente para realizar esta operação',
      });
      return
    }

    if (!user.pix_key) {
      setCompleteRegister(true)
      addToast({
        type: 'info',
        title: 'Finalize seu cadastro',
        description: 'Cadastro incompleto, finalize o cadastro',
      });
      return
    }

    try {
      await api.post("/transactions", {
        type: 0,
        value: Number(value),
      }).then(response => {
        if (response.data)
          user.balance -= Number(value);
        updateUser(user)

        addToast({
          type: 'success',
          title: 'Solicitação realizada com sucesso',
          description: 'Em breve o dinheiro sera creditado em sua chave pix!',
        });
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na transação',
        description: 'Entre em contato com o suporte',
      });
    }

  }

  async function handleCompleteRegister() {
    try {
      const response = await api.put('/users/me', {
        phone,
        pix_key,
        document,
        type_pix_key
      })

      updateUser(response.data);
      addToast({
        type: 'success',
        title: 'Sucesso na atualização',
        description: 'Dados atualizados com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description: 'Ocorreu um erro ao atualizar seu cadastro, entre em contato com o suporte',
      });
    }
  }

  const handleNav = useCallback((name) => {
    if (name === "perfil") {
      setPerfil(true);
      setCarteira(false);
    }
    if (name === "carteira") {
      setPerfil(false);
      setCarteira(true);
    }
  }, [])

  return (
    <Container >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.3)',
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            width: "50%",
            minWidth: '350px',
            height: "320px",
            margin: 'auto',
            background: "#c6c6c6"
          }

        }}
      >
        <ModalHeader>
          <h2>Importante</h2>
          <button onClick={closeModal}>x</button>
        </ModalHeader>
        <ContentModal>
          <p>Tem certeza que deseja continuar, esta ação irá fazer sua inscrição em uma copa e é irreversivel, o custo sera de {valueAction} ficha(s)</p>
          <ContentButtonsModal>
            <AcceptButton onClick={() => handleSubmitCup(valueAction)}>
              Aceitar
            </AcceptButton>
            <RefusedButton onClick={closeModal}>
              Cancelar
            </RefusedButton>
          </ContentButtonsModal>
        </ContentModal>
      </Modal>
      <Menu>
        <ContentMenu>
          <Title>Area administrativa</Title>
          <UserInfo >
            <UserName onClick={() => setOpenOption(!openOption)}>{user.name}</UserName>
          </UserInfo>
          {openOption && <div className="Box" >
            <button onClick={() => handleNav('carteira')}><p>Carteira</p></button>
            <button onClick={() => handleNav('perfil')}><p>Perfil</p></button>
            <button onClick={signOut}><p>Sair</p><GoSignOut size={22} /></button>
          </div>}
        </ContentMenu>
      </Menu>
      {carteira &&
        <Content >
          <Wrapper>
            {completeRegister &&
              <Profile>
              <span>Completar meu cadastro</span>
              <div>
                <label>Nome:</label>
                <input type="text" name="name" value={user.name} />
              </div>
              <div>
                <label>Telefone:</label>
                <input type="text" name="phone" value={phone} onChange={(e) => {setPhone(e.target.value)}} />
              </div>
              <div>
                <label>CPF/CNPJ:</label>
                <input type="text" name="document" value={document} onChange={(e) => {setDocument(e.target.value)}} />
              </div>
              <div>
                <label>Tipo de chave PIX</label>
                <div className='contentOptionKey' >
                  <button className={type_pix_key === 'doc' ? 'selected' : ''} onClick={() => setType_pix_key('doc')}><HiIdentification size={22} />CPF/CNPJ</button>
                  <button className={type_pix_key === 'phone' ? 'selected' : ''} onClick={() => setType_pix_key('phone')}><MdPhoneIphone size={22} />Telefone</button>
                  <button className={type_pix_key === 'key' ? 'selected' : ''} onClick={() => setType_pix_key('key')}><GoKey size={22} />Chave aleatória</button>
                  <button className={type_pix_key === 'mail' ? 'selected' : ''} onClick={() => setType_pix_key('mail')}><GoMail size={22} />E-mail</button>
                </div>
              </div>
              <div>
                <label>Chave Pix</label>
                <input type="text" name="pixkey"  value={pix_key} onChange={(e) => {setPix_key(e.target.value)}}/>
              </div>
              <div className='contentButton'>
                <button type="button" onClick={handleCompleteRegister}>Atualizar cadastro</button>
              </div>
            </Profile>
            }

            <Card1>
              <CardTitle>Saldos</CardTitle>
              <CardInfo>
                <Coins>
                  <h3>Coins: </h3>
                  <h1>{user.coins}</h1>
                  <img src={money} alt="fichas" />
                </Coins>
                <Balance>
                  <h3>Saldo disponível para saque:</h3>
                  <h1>{Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(Number(user.balance))}</h1>
                </Balance>
              </CardInfo>
            </Card1>
            <Card2>
              <CardTitle>Solicitações</CardTitle>
              <CardAction>
                <input type='text' placeholder="Digite o valor que deseja solicitar" value={Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(Number(cashOut))} onChange={e => setCashOut(maskPrice(e.target.value))} />
                <button type="button" onClick={() => handleAppointment(cashOut)}>Solicitar saque</button>
              </CardAction>
            </Card2>

          </Wrapper>
          <Wrapper>
            <Cups>
              <CardTitle>Copas disponíveis</CardTitle>
              {/* {cups &&
              cups.map(cup => (
                <Cup key={cup.id} onClick={() => openModal(cup.id, cup.price)} disabled={cup.active === 0}>
                  <h3>{cup.description}</h3>
                  <p>{(cup.active === 0) ? (cup.dataActive ? "Count" : "Em Breve") : "Sala aberta"}</p>
                  <img src={`./${cup.price}.png`} alt={cup.description} />
                </Cup>    
              ))} */}
              <Cup onClick={() => openModal(2)}>
                <h3>Copa diária</h3>
                <p>Sala aberta</p>
                <img src={`./2.png`} alt="copa day" />
              </Cup>
              <CardTitle>Ranking da copa diária</CardTitle>
              <div className='infos'>
                {/* <div className="row">
                <span>Quantos jogadores entraram:</span>
                <strong>{data !== null && data.quantity > 0 ? data.quantity : 0}</strong>
              </div> */}
                <div className="row">
                  <span>Prêmio 1º lugar:</span>
                  <strong>{Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(data !== null ? Number(data?.bedroom?.champion_value) : 10)}</strong>
                  <span>Prêmio 2º lugar:</span>
                  <strong>{Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(data !== null ? Number(data?.bedroom?.second_value) : 2)}</strong>
                </div>
              </div>
              <div className="ranking">
                {data !== null && data.quantity > 0 ?
                  <ul>
                    {data.users !== undefined && data?.users.length > 0 && data.users.map((user, index) => (
                      <li>
                        <div className="row">
                          <strong>{index + 1}º</strong>
                          <span>{user.user.name}</span>
                        </div>
                        <div className="row">
                          <strong>Acertos</strong>
                          <span>{user.quantity}</span>
                          <strong>Tempo</strong>
                          <span>{(240000 - Number(user.time)) / 1000}seg</span>
                        </div>
                      </li>
                    ))}
                  </ul> : <h3>Seja o primeiro a jogar</h3>}
              </div>
            </Cups>
            <Buy>

              <CardTitle>Compre mais fichas</CardTitle>
              <Plains>
                <Plan>
                  <h3>Compre 10 fichas</h3>
                  <div>
                    <p>+10</p><img src={money} alt="fichas" />
                  </div>

                  <button type="button" onClick={() => handleCredit(10)}>Compre agora!</button>
                </Plan>
                <Plan>
                  <h3>Compre 25 fichas</h3>
                  <div>
                    <p>+25</p><img src={money} alt="fichas" />
                  </div>
                  <button type="button" onClick={() => handleCredit(25)}>Compre agora!</button>
                </Plan>
                <Plan>
                  <h3>Compre 50 fichas</h3>
                  <div>
                    <p>+50</p><img src={money} alt="fichas" />
                  </div>
                  <button type="button" onClick={() => handleCredit(50)}>Compre agora!</button>
                </Plan>
              </Plains>
            </Buy>
          </Wrapper>
        </Content >}
      {perfil &&
        <Content >
          <Wrapper>
            <Profile>
              <span>Meu perfil</span>
              <div>
                <label>Nome:</label>
                <input type="text" name="name" value={user.name} />
              </div>
              <div>
                <label>Telefone:</label>
                <input type="text" name="phone" value={phone} onChange={(e) => {setPhone(e.target.value)}} />
              </div>
              <div>
                <label>CPF/CNPJ:</label>
                <input type="text" name="document" value={document} onChange={(e) => {setDocument(e.target.value)}} />
              </div>
              <div>
                <label>Tipo de chave PIX</label>
                <div className='contentOptionKey' >
                <button className={type_pix_key === 'doc' ? 'selected' : ''} onClick={() => setType_pix_key('doc')}><HiIdentification size={22} />CPF/CNPJ</button>
                  <button className={type_pix_key === 'phone' ? 'selected' : ''} onClick={() => setType_pix_key('phone')}><MdPhoneIphone size={22} />Telefone</button>
                  <button className={type_pix_key === 'key' ? 'selected' : ''} onClick={() => setType_pix_key('key')}><GoKey size={22} />Chave aleatória</button>
                  <button className={type_pix_key === 'mail' ? 'selected' : ''} onClick={() => setType_pix_key('mail')}><GoMail size={22} />E-mail</button>
                </div>
              </div>
              <div>
                <label>Chave Pix</label>
                <input type="text" name="pixkey"  value={pix_key} onChange={(e) => {setPix_key(e.target.value)}}/>
              </div>
              <div className='contentButton'>
                <button type="button" onClick={handleCompleteRegister}>Atualizar cadastro</button>
              </div>
            </Profile>
          </Wrapper>
        </Content >
      }
    </Container>
  )
}