import React from 'react';
import { FiMail, FiMapPin } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineCaretRight } from 'react-icons/ai';

import {
  Container,
  ContentFooter,
  Logo,
  Contact,
} from './styles';
import { Link } from 'react-router-dom';
import { GoToTop } from '../../utils/GoToTop';

export function Footer(){
  return(
      <Container>
        <ContentFooter>
          <Logo>
            {/* <ReactSVG src={logo} style={{
              marginBottom: '1rem'
            }}/> */}

            <ul>
              <li>
                <FiMail size={24} />
                <Link to="/">atendimento@equizpremiado.com.br</Link>
              </li>
              {/* <li>
                <FiPhone size={24} />
                <Link to="/">Fixo: (34) 3633-1677</Link>
              </li> */}
              <li>
                <FaWhatsapp size={24} />
                <Link to="/">WhatsApp: (34) 9.89170-6160</Link>
              </li>

              <li>
                <FiMapPin size={24} />
                <Link to="/">Rua Dois, nº40 - Centro, Tapira-MG</Link>
              </li>
            </ul>
          </Logo>
          <Contact>
            {/* <h3>Explore</h3> */}
            <ul>
              <li>
                <AiOutlineCaretRight size={20} />
                <Link to="/">
                  Home
                </Link>
              </li>
              <li>
                <AiOutlineCaretRight size={20} />
                <Link to="/wallet">
                  Minha conta
                </Link>
              </li>
              <li>
                <AiOutlineCaretRight size={20} />
                <Link to="/privacyPolicy">
                  Política de privacidade
                </Link>
              </li>
            </ul>
          </Contact>
          <Contact>
            {/* <h3>Explore</h3> */}
            <ul>
              <li>
                <AiOutlineCaretRight size={20} />
                <Link to="/about">
                  Quem somos
                </Link>
              </li>
              <li>
                <AiOutlineCaretRight size={20} />
                <Link to="/usage">
                  Como funciona
                </Link>
              </li>
              <li>
                <AiOutlineCaretRight size={20} />
                <Link to="/faqs">
                  Dúvidas frequentes
                </Link>
              </li>
            </ul>
          </Contact>
        </ContentFooter>
        <GoToTop />
      </Container>
  )
}