import styled from "styled-components";

import bg from "../../assets/paralax.jpg";
import fundo from "../../assets/fundo02.jpg";

export const Paralax = styled.div`
  display: flex;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  @media (max-width: 800px) {
    height: 150vh;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  background-color: rgba(26, 26, 26, 0.87);

  section {
    max-width: 70rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4.5rem 1rem 2rem;
    align-items: center;

    @media (max-width: 800px) {
      padding: 1rem;
      flex-direction: column;
    }

    h3 {
      font-family: OswaldS;
      font-size: 1.5rem;
      color: #ff9c00;
      font-style: italic;
      @media (max-width: 800px) {
        text-align: center;
      }
    }

    h1 {
      font-family: Odibee;
      font-size: 3.75rem;
      color: #fff;
      font-style: italic;
      margin-top: 1rem;
      @media (max-width: 800px) {
        text-align: center;
      }
    }

    div.mark {
      background-color: #ff9c00;
      width: 100px;
      height: 3px;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  .Divisor {
    background-color: #111;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9rem;
    padding: 1rem;

    h2 {
      font-size: 2rem;
      font-family: OswaldR;
      font-style: italic;
      color: #fff;
      text-align: center;
    }
  }

  .textSectionTwo {
    width: 50%;

    @media (max-width: 800px) {
      width: 100%;
      padding: 1rem;
      text-align: center;
      flex-direction: column;
    }

    h1 {
      font-family: Birds;
    }

    p {
      font-size: 1.3rem;
      color: #fff;
      font-family: "Tahoma", Geneva, Verdana, sans-serif;
      margin-bottom: 0.9rem;
    }

    label {
      font-size: 1.3rem;
      color: #fff;
      font-family: "Tahoma", Geneva, Verdana, sans-serif;
      font-style: italic;
    }
  }

  .sectionThree {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;

    width: 100%;
    background-image: url(${fundo});
    background-size: cover;
    height: 700px;

    @media (max-width: 1000px) {
      width: 100%;
      height: auto;
      flex-direction: column;
      padding: 1rem;
    }

    .Counters {
      display: flex;
      justify-content: space-between;

      @media (max-width: 800px) {
        display: none;
        width: 100%;
        flex-direction: column;
        padding: 1rem;
      }
    }
  }

  .imageSectionThree {
    width: 500px;
    height: 31rem;
    position: relative;
    display: flex;
    object-fit: contain;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    border-style: solid;
    border-width: 0px 0px 3px 3px;
    border-color: #ff9c00;

    @media (max-width: 1000px) {
      width: 100%;
      height: 45rem;
      margin-bottom: 1rem;
    }

    @media (max-width: 800px) {
      display: none;
    }

    img {
      position: relative;
      height: 100%;
      left: -2rem;
    }
  }

  .textSectionThree {
    width: 50%;

    @media (max-width: 1000px) {
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    h3 {
      font-family: OswaldS;
      font-size: 1.5rem;
      color: #ff9c00;
      font-style: italic;
    }

    h1 {
      font-family: Odibee;
      font-size: 3.75rem;
      color: #fff;
      font-style: italic;
      margin-top: 1rem;
    }

    p {
      font-size: 1.3rem;
      color: #fff;
      font-family: "Tahoma", Geneva, Verdana, sans-serif;
      margin-bottom: 0.9rem;
    }

    label {
      font-size: 1.3rem;
      color: #fff;
      font-family: "Tahoma", Geneva, Verdana, sans-serif;
      font-style: italic;
    }
  }
`;

export const ContentMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, #1f8a70 0%, #111111 100%);
  backdrop-filter: blur(42px);

  @media (max-width: 800px) {
    justify-content: flex-end;
    min-width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

export const MenuMobile = styled.div`
  max-width: 100%;
  height: 5rem;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  display: none;

  ul {
    list-style: none;

    li {
      display: inline;
      padding: 1rem;

      a {
        text-decoration: none;
        font-family: Birds;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fafafa;
        transition: color 0.3s;

        &.active {
          color: #ff9c00;
        }

        &:hover {
          color: #ff9c00;
        }
      }
    }
  }
  @media (max-width: 800px) {
    display: flex;
  }
`;

export const Store = styled.div`
  width: 100%;
  display: flex;
  a{
    width: 45%;
    margin: 1rem;
    img {
      width: 100%;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    padding: 1rem;
    a{
      width: 100%;
      padding: 1rem 0;
      img {
        width: 100%;
      }
    }
  }
`;

export const Menu = styled.div`
  max-width: 70rem;
  height: 5rem;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  display: flex;

  ul {
    list-style: none;

    li {
      display: inline;
      padding: 1rem;
      a {
        text-decoration: none;
        font-family: Birds;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        transition: color 0.3s;

        &.active {
          color: #ff9c00;
        }

        &:hover {
          color: #ff9c00;
        }
      }
    }
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const BanMobile = styled.img`
  display: none;
  @media (max-width: 800px) {
    display: inherit;
    width: 100%;
    height: 100vh   ;
    object-fit: cover;
    filter: brightness(0.6);
  }
`;

export const Banner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 5rem);
  position: relative;
  overflow: hidden;

  & > div {
    max-width: 70rem;
    width: 100%;
    position: absolute;
    z-index: 5;

    #infoBan {
      padding: 0 1rem;
    }

    .mark {
      background-color: #ff9c00;
      width: 100px;
      height: 3px;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }

    img {
      width: 7rem;
      margin-top: -4.5rem;
      z-index: 5;
      position: relative;
      filter: brightness(0.6);
      margin-left: 1rem;
    }

    img#logo {
      filter: brightness(1);
    }
    h3 {
      font-family: OswaldS;
      font-size: 1.5rem;
      color: #ff9c00;
      font-style: italic;
    }

    p {
      margin-bottom: 1rem;
      font-family: OswaldR;
      font-size: 1rem;
      color: #fff;
    }
  }

  @media (max-width: 800px) {
    & > div {
      padding: 1rem;
      z-index: 5;
      object-fit: cover;

      #infoBan {
        display: flex;
        align-items: center;
        flex-direction: column;

        .mark {
          background-color: #ff9c00;
          width: 100px;
          height: 3px;
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;
        }
      }

      img {
        width: 5rem;
      }

      h3 {
        text-align: center;
        margin-bottom: 2.5rem;
        font-size: 2.5rem;
      }

      h1 {
        text-align: center;
        width: 100%;
        font-size: 4rem;
      }

      p {
        font-size: 2rem;
        margin-bottom: 2.5rem;
      }
    }
  }
`;

export const AnimeBan = styled.div`
  min-width: 100vw;;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media (max-width: 800px) {
    & {
      display: none;
    }
  }
`;

export const Title = styled.h1`
  width: 60%;
  font-family: Birds;
  font-size: 6.5rem;
  color: #fff;
`;

export const Button = styled.button`
  display: flex;
  padding: 0.5rem 1rem;
  border: 2px solid #ff9c00;
  margin-right: 1rem;
  background-color: transparent;
  color: #ff9c00;
  font-family: OswaldS;
  font-size: 1.5rem;
  font-style: italic;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #ff9c00;
    color: #fff;
    cursor: pointer;
  }
`;

export const ContentThree = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  div.disImg {
    height: 17.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 1rem;

    h1 {
      font-family: Odibee;
      font-size: 5rem;
      color: #fff;
      font-style: italic;
    }

    h3 {
      color: #fff;
    }
  }
  .imgesq {
    width: 15rem;
    transition: transform 0.3s;
    &:hover {
      transform: rotate(4deg) scale(1.1);
    }

    @media (max-width: 800px) {
      width: 10rem;
    }
  }

  .imgdir {
    width: 10rem;
    margin-bottom: 1rem;
    transition: transform 0.3s;
    &:hover {
      transform: rotate(4deg) scale(1.1);
    }

    @media (max-width: 800px) {
      width: 7rem;
    }
  }

  .imageSectionTwo {
    width: 50%;
    height: 36rem;
    position: relative;
    display: flex;
    object-fit: contain;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    border-style: solid;
    border-width: 0px 3px 3px 0px;
    border-color: #75d918;

    img {
      height: 100%;
    }

    @media (max-width: 800px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .imageSectionThree {
    width: 500px;
    height: 31rem;
    position: relative;
    display: flex;
    object-fit: contain;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    // border-style: solid;
    // border-width: 0px 0px 3px 3px;
    // border-color: #ff9c00;

    img {
      position: relative;
      height: 100%;
      left: -2rem;
    }
  }
`;

export const ProgressBar = styled.div`
  height: 2.75rem;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  div {
    background-color: #418b70;
    height: 2.75rem;
    display: flex;
    align-items: center;

    span {
      font-family: Birds;
      padding: 1rem;
      font-size: 1.2rem;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;

      line-height: 2rem; /* fallback */
      max-height: 4rem; /* fallback */
      -webkit-line-clamp: 1;
    }
  }
`;

export const Count = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;
    padding: 1rem;
  }

  h2 {
    font-family: Odibee;
    color: #ff9c00;
    font-size: 4rem;
  }

  span {
    font-family: Odibee;
    color: #fff;
    font-size: 1.5rem;
  }
`;

export const ContentCups = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70rem;
  width: 100%;

  @media (max-width: 800px) {
    padding: 1rem;
  }

  hr {
    margin: 1.5rem 0;
    border-color: #ff9c00;
  }
`;

export const ButtonCup = styled.button`
  display: flex;
  min-width: 100%;
  width: 100%;
  height: 15rem;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  padding: 1rem;
  transition: background-color 1s, border-color 1s;

  &:hover {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #ff9c00;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & > div {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 800px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  img {
    height: 90%;
    margin-right: 8rem;
    transition: transform 0.4s;

    @media (max-width: 900px) {
      margin-right: 1rem;
    }

    @media (max-width: 700px) {
      display: none;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const ContentTime = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  div {
    h1 {
      font-size: 6rem;

      @media (max-width: 700px) {
        font-size: 4rem;
      }
    }
    p {
      font-family: Odibee;
      color: #ff9c00;
      font-size: 1.5rem;

      @media (max-width: 700px) {
        font-size: 1.2rem;
      }
    }
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const CardPlay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0.5rem;
  width: 23%;
  background-color: #111;
  border-width: 5px 0 0 0;
  border-style: solid;
  border-color: #111;
  align-items: center;
  transition: border-color 0.4s;
  margin-top: 3rem;

  @media (max-width: 920px) {
    width: 48%;
  }

  &:hover {
    border-color: #ff9c00;
  }

  img {
    margin-bottom: 1rem;

    @media (max-width: 580px) {
      width: 100%;
    }
  }
`;

export const ContentCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 70rem;
  width: 100%;

  @media (max-width: 930px) {
    padding: 0 1rem;
  }

  hr {
    margin: 1.5rem 0;
    border-color: #ff9c00;
  }
`;

export const Footer = styled.div`
  background-image: url(${fundo});
  display: flex;
  justify-content: center;
`;

export const ContentFooter = styled.div`
  display: flex;
  max-width: 70rem;
  padding: 2rem;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  padding-right: 1rem;

  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 1rem;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  img {
    width: 200px;
    @media (max-width: 800px) {
      width: 150px;
    }
  }

  #address {
    margin: 1rem 0;
    width: 40%;
    p {
      color: #fff;
      font-family: OswaldL;
      font-size: 1.2rem;
    }
  }

  ul {
    li {
      display: flex;
      align-items: center;
      padding: 0.5rem;

      svg {
        margin-right: 1rem;
        color: #ff9c00;
      }

      a {
        text-decoration: none;
        color: #fff;
        transition: color 0.3s;
        font-family: OswaldL;
        font-size: 1.2rem;

        &:hover {
          color: #ff9c00;
        }
      }
    }
  }
`;

export const Contact = styled.div`
  h3 {
    font-family: OswaldS;
    font-size: 2rem;
    color: #ff9c00;
    font-style: italic;
    margin-bottom: 1rem;
    @media (max-width: 800px) {
      text-align: left;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    text-align: center;
  }

  ul {
    li {
      list-style: none;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: #fff;
        font-family: OswaldR;
        font-size: 1.2rem;
        transition: color 0.3s;

        &:hover {
          color: #ff9c00;
        }
      }

      svg {
        color: #ff9c00;
        margin-right: 0.5rem;
      }
    }

    @media (max-width: 1000px) {
      display: inline-flex;
      li {
        padding-right: 2rem;
      }
    }

    @media (max-width: 500px) {
      display: block;
    }
  }
`;

export const Copyright = styled.div`
  display: flex;
  background-color: #000;
  color: #ff9c00;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;
