import React from "react";
import { Footer } from "../../components/Footer";

import { Container, Content, Paralax } from './styles';

export function Usage() {
  return (
    <>
      <Paralax />
      <Container >
        <Content>
          <h2 id="topo">Como funciona?</h2>
          <h3>Participação: </h3>
          <p>Antes de mais nada a pessoa interessada, esta deve ser maior de 18 anos,
            deverá se cadastrar e comprar o número de fichas que desejar na aba “comprar fichas”.
            Uma vez adquiridas as fichas o usuário, maior de 18 anos, interessado em participar
            do QUIZ, deverá comprar sua entrada para o mesmo, na forma de fichas, que serão debitadas
            do seu montante de fichas na conta. Essa entrada no torneio terá valores variados,
            definindo assim qual prêmio você levará se vencer seus oponentes no torneio escolhido.
            Uma vez adquirida a entrada a pessoa está apta a participar do QUIZ.
            Haverá salas de diferentes valores e diferentes modalidades.</p>

          <h3>Dos valores</h3>

          <strong>Copa diária</strong>

          <p>O QUIZ terá várias modalidades, exemplo teremos vários torneios de QUIZ abertos,
            sendo distribuídos dentre os seguintes temas, por exemplo: matemática, português,
            história, geografia, esportes, filmes, atualidades, política, variados, etc.
            O usuário pode escolher de qual modalidade participar quando tivermos salas abertas.
            No entanto os primeiros torneios serão com temas sortidos.</p>

          <p>O prêmio a ser pago ao campeão e vice-campeão será calculado de 2 formas.</p>
          <ul>
            <li>
              <p>
                Até (≤) 10 participantes: O preço que se paga pela entrada será multiplicado por 5, ou seja, o CAMPEÃO da copa diária com valor de R$ 2,00 reais a entrada, terá o prêmio de R$10,00 reais. E o VICE-CAMPEÃO terá o valor investido na sala, resgatado.
              </p>
            </li>
            <li>
              <p>
                Acima (≥) de 11 participantes: O valor pago será calculado de forma crescente, uma graduação percentual previamente definida pela empresa e pode ser consultada. O valor de R$ 10,00 será o valor base (cálculo acima) e os valores referente ao cálculo de percentual será somado ao valor base. Exemplo: R$10,00 (valor base) + R$4,00* (valor percentual acima de 11 participantes) = R$ 14,00. Este valor seria o prêmio para o campeão. E o vice-campeão terá o valor investido na sala, resgatado + o percentual de cálculo somado. Exemplo: R$2,00 (valor base) + R$0,50* (valor percentual acima de 11 participantes) = R$ 2,50. Este seria o prêmio para o Campeão.
                *Valore utilizados apenas para elucidar o cálculo, ou seja, não são valores reais.

              </p>
            </li>
          </ul>

          <strong>Copa especial (com data e hora marcada):</strong>
          <p>Os valores a serem pagos ao campeão e vice-campeão será calculado a partir do valor de entrada. Cada copa terá seu valor elucidado tanto no aplicativo quando no site. O valor de entrada será multiplicado 5x para gerar o prêmio do Campeão e 1x para o prêmio do vice-campeão. Exemplo copa two: R$ 2,00 x 5 = R$ 10,00 prêmio para o campeão.
            R$ 2,00 x 1 = R$ 2,00 prêmio para o vice-campeão.</p>

          <h3>DAS MODALIDADES</h3>
          <p>O QUIZ terá várias modalidades, teremos COPAS DIÁRIAS E COPAS ESPECIAIS. Ambas de vários valores, estes expressos em seu nome, podendo assim o usuário decidir de qual copa participar. Exemplo: Copa diária - Two (R$ 2,00 reais) Copa Especial - Five (R$ 5,00 reais).
            Todo e qualquer Quiz referente ao EQuiz Premiado, terá suas perguntas distribuídas entre vários temas, exemplo: matemática, português, história, geografia, esportes, filmes, música, idiomas, atualidades, política, etc.</p>

          <strong>Copa diária:</strong>
          <p>O QUIZ se apresentará na forma de copa randômica e com RANKING. A sala ficará aberta por 24 horas, podendo cada usuário responder as perguntas quantas vezes quiser. Tendo assim a chance de se superar, superar os demais competidores e subir no ranking.</p>

          <strong>Funcionalidade:</strong>
          <p>Cada sala da copa diária ficará ABERTA POR 24 HORAS. O usuário terá este período determinado para entrar e responder as perguntas. Cada sala será composta por 15 perguntas aleatórias e o tempo máximo para responder todas é de 4 minutos. Ao enviar as respostas, o usuário será direcionado para tela onde verá a quantidade de acertos e o tempo gasto para reponde-las. O usuário poderá também acompanhar o ranking na tela inicial.
            Será o vencedor aquele que conseguir responder MAIS PERGUNTAS CORRETAMENTE e o critério de desempate será o MENOR TEMPO GASTO.</p>

          <p>O vencedor levará um prêmio em dinheiro que pode ser retirado para sua conta pessoal de escolha, assim que o mesmo estiver disponível na carteira.</p>


          <h3>Copa especial:</h3>
          <p>O QUIZ se apresentará na forma de um TORNEIO ELIMINATÓRIO com 4 FASES para quem ir até a final. Esta modalidade acontecerá 1 vez por semana, aos domingos, e terá data e hora marcada. As informações, tais como data e horário estarão disponíveis no app e no site. E serão também veiculadas nas redes sociais da empresa.</p>

          <strong>Funcionalidade:</strong>
          <p>Na copa eliminatória o vencedor de cada fase avança para a próxima etapa, até que chegue à final, onde haverá dois participantes, que disputará pelo título de CAMPEÃO e VICE-CAMPEÃO.</p>

          <p>Será o vencedor de cada etapa aquele que conseguir responder MAIS PERGUNTAS CORRETAMENTE e o critério de desempate será o MENOR TEMPO. Cada fase da copa será composta por 10 QUESTÕES idênticas para todos os participantes. E o tempo de 3 minutos para responder todas as questões. O tempo é cronometrado considerando até os milésimos de segundos, pois em caso de empate, este será o quesito verificado para apontar o vencedor. O vencedor levará um prêmio em dinheiro que pode ser retirado para sua conta pessoal de escolha, assim que o mesmo estiver disponível na carteira.</p>

          <strong>DAS REGRAS</strong>
          <p>
            O usuário que entrar na sala escolhida e sair da mesma antes de finalizar o questionário, 
            NÃO terá seu coin estornado, exceto quando o mesmo for algum erro do sistema. 
            Se a tela/página do questionário for atualizada enquanto o jogador estiver respondendo, 
            o valor de outra entrada (valor da sala que tenha escolhido par participar) será debitado do saldo do usuário. 
            Desta forma, orientamos aos usuários que mantenha total atenção na tela enquanto estiver respondendo o 
            questionário.
          </p>

          <strong>DAS ENTRADAS</strong>
          <p>Os coins para o QUIZ, são exclusivos e custarão R$1,00 cada, sendo que para cada copa do EQuiz Premiado terá um valor pré-anunciado no aplicativo e site.</p>

          <p>Sobre valores: Os valores expressos no site são divididos entre ENTRADA E SAÍDA e trabalhadas entre COINS e PRÊMIO.
            Entrada é o valor que O USUÁRIO utilizou para comprar fichas para participar do torneio. Sendo este valor transformado em coins IMEDIANTAMENTE e assim impossibilitado de ser sacado.</p>
          <ul>
            <li>
              <p>
                COINS é o meio utilizado para pagar o torneio assim que o usuário decidir de qual copa irá participar. Sendo este símbolo fictício para pagamento e assim impossibilitado de ser sacado.

              </p>
            </li>
            <li>
              <p>
                PRÊMIO é o valor que foi adquirido pelo campeão ou vice-campeão que poderá ser sacado da sua carteira imediatamente ou continuar na carteira e ser utilizado para novas compras de coins.
              </p>
            </li>
          </ul>


          <p>Fica expresso que o usuário NÃO PODERÁ SACAR COINS. O usuário PODE SACAR OS VALORES RECEBIDOS EM PRÊMIOS a qualquer momento. Todos os envios de valores ao usuário, serão feitos através da plataforma Mercado Pago (exceto se o usuário, optar por transferência no caso de valores acima de R$10.000,00). Ficando assim, a utilização de todas as coins de responsabilidade do usuário.</p>

          <p>Tendo o usuário consciência que nenhum valor e/ou símbolo fictício utilizado dentro da plataforma web e app que represente dinheiro (R$) poderá ser sacado da carteira, apenas os valores expressos em “SALDO PARA SAQUES”, sendo este direcionado de prêmios do campeão e vice-campeão do torneio.</p>

          <strong>DO VENCEDOR</strong>
          <strong>Copa especial:</strong>
          <p>O vencedor é aquele que responder corretamente mais perguntas em menos tempo. Sempre dois concorrentes para a modalidade de copa especial, sendo que cada vencedor avança para a etapa seguinte e aguarda seu adversário. Como cada disputa é cronometrada, existe o tempo máximo para responder as perguntas e iniciar a fase seguinte. Sendo assim, aquele que não continuar na página após o avançar de fase será desclassificado, perdendo por WO.</p>

          <strong>Na modalidade ranking:</strong>
          <p> O vencedor é aquele que acertar o maior número de perguntas utilizando o menor tempo.</p>
          <p> *O prêmio sempre é dado ao 1º e 2º lugar, no caso de empates para qualquer uma das competições o prêmio para o 1º colocado é divido para os usuários que acertarem exatamente o mesmo número de perguntas exatamente com mesmo tempo (lembrando que a contagem incluí milésimos de segundos), empatando em primeiro lugar. O mesmo vale para caso de empate na segunda colocação.</p>

        </Content>
      </Container>
      <Footer />
    </>
  )
}





