import React from "react";
import { Footer } from "../../components/Footer";

import { Container, Content, Paralax } from './styles';

export function Faqs() {
  return (
    <>
      <Paralax />
      <Container >
        <Content>
          <h2 id="topo">PERGUNTAS E RESPOSTAS FREQUENTES</h2>

          <h3 style={{ color: "#FF9c00"}}>1. Quem pode participar? </h3>
          <p>A participação do Quiz Premiado e para toda e qualquer pessoa que for maior de 18 anos.  </p>

          <h3 style={{ color: "#FF9c00"}}>2. Como participo do QUIZ PREMIADO? </h3>
          <p>Uma vez cadastrado, você deve seguir para a compra de suas fichas na aba COMPRAR FICHAS. Em seguida, o usuário poderá escolher de qual copa deseja participar e assim fazer o pagamento via fichas. Assim que a copa for selecionada e o pagamento realizado, já está tudo certo para o usuário participar do torneio que estiver aberto e selecionado.</p>

          <p>Ressalta-se que as copas especiais terão início com 16 participantes. Caso a sala não se complete até o momento de start da partida a mesma será excluída e as fichas utilizadas serão ressarcidas aos usuários que poderão escolher outro torneio para participar. </p>
          <p>Na copa diária, o usuário terá o período de 24 horas para participar. Ao entrar na sala o tempo máximo para responder as 15 questões é de 4 minutos.</p>

          <p>Após o final do torneio, será o CAMPEÃO, aquele que acertar maior número de perguntas em menos tempo e VICE-CAMPEÃO o que ficar com o segundo melhor resultado em respostas ou tempo em caso de empate.</p>

          <h3 style={{ color: "#FF9c00"}}> 3. Como compra entradas para o EQUIZ PREMIADO? </h3>
          <p>Acessando o menu principal a opção COMPRAR FICHAS estará visível. Ela também pode ser localizada em várias abas do site. Inserindo a quantidade e a forma de pagamento desejada o usuário poderá confirmar a compra das fichas. A confirmação é realizada via mercado pago, assim que o mesmo for atualizado sobre o pagamento realizado as fichas serão disponibilizadas em sua conta no site e app, podendo ser usadas imediatamente para entrar no QUIZ PREMIADO. </p>

          <h3 style={{ color: "#FF9c00"}}>4. Quanto é o meu prêmio no quiz? </h3>
          <p>Nas batalhas diárias o prêmio é 5 vezes maior que o valor da entrada (exemplo: para entrada de R$10,00 o vencedor receberá R$50,00 em prêmio e o 2º colocado resgatará o valor investido para entrar na sala R$10,00), sendo premiados apenas o CAMPEÃO e o VICE-CAMPEÃO de cada torneio. </p>


          <h3 style={{ color: "#FF9c00"}} >5. Sobre o que são as perguntas?</h3>
          <p>O banco de dados tem perguntas referente a todos os temas. Entre eles: atualidades, esportes, filmes, geografia, história, inglês, matemática, politica, português, series, etc.</p>

          <h3 style={{ color: "#FF9c00"}}> 6. Entrei na batalha, posso desistir?</h3>
          <p>Uma vez debitadas as fichas da entrada do torneio em seu saldo, o valor não será reembolsável. Ou seja, o abandono do torneio ou batalha irá acarretar na perda das fichas. Pois, as mesmas não são reembolsáveis.</p>

          <h3 style={{ color: "#FF9c00"}}> 7. Perdi o horário do torneio ou batalha, o que fazer? </h3>
          <p>Todos os torneios disponíveis terão dia e horário marcado para acontecer, fica então a total responsabilidade do usuário de averiguar os horários. O site e app não se responsabiliza pelo não comparecimento do usuário, salvo em algum problema que seja gerado pela plataforma, nesse caso o torneio será repetido e nenhum usuário será lesado.</p>

          <h3 style={{ color: "#FF9c00"}}> 8. Formas de pagamento? </h3>
          <p>Aceitamos pagamento por todos os meios expressos nos termos de uso; cartões, transferência e PIX.</p>

          <h3 style={{ color: "#FF9c00"}}> 9. Quanto tempo minhas fichas entram no meu saldo após a compra? </h3>
          <p>Depende do método de pagamento; uma vez identificado o pagamento as fichas são creditadas em no máximo 10 minutos. </p>

          <h3 style={{ color: "#FF9c00"}}>  10. Qual o tempo de validade das fichas? </h3>
          <p>As fichas são vitalícias, ou seja, não prazo definido para expirar. As mesmas podem ser utilizadas a qualquer momento. Ressalta-se, para que não exista nenhum problema com a utilização das fichas o cadastro do usuário deverá estar sempre atualizado.</p>

          <div style={{
            background: "#383838",
            padding: "1rem",
            borderRadius: "5px",
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}>
            <h3>Tenho uma dúvida que não está nas FAQ’s</h3>

            <p style={{ textAlign: 'center', fontSize: "1rem"}}>Entre em contato com nossa equipe pelos contatos a seguir e tenha sua dúvida sanada no prazo estipulado nos termos de uso:</p>
            <p><strong>Email: </strong>atendimento@equizpremiado.com.br</p>
            <p><strong>Tel: </strong>(64) 9.8170-6160</p>
            <p><strong>WhatsApp: </strong>(64) 9.8170-6160</p>
          </div>

        </Content>
      </Container>
      <Footer />
    </>
  )
}





