import React, { FormEvent, useCallback, useEffect, useState, } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { Paralax, Container, BoxLogin, Form } from "./styles"

interface ParamsProps {
  phone: string;
  email: string;
}



export default function CheckCode() {
  const [code, setCode] = useState('')
  const { addToast } = useToast();
  const history = useHistory();
  const [time, setTime] = useState(180);
  const [req, setReq] = useState(2);

  const { phone, email } = useParams() as ParamsProps;


  async function handleRecovery(event: FormEvent) {
    event.preventDefault();
    try {
      api.post("/users/checkcode", {
        code,
        phone
      }).then(res => {
        if (res.status === 201) {
          history.push(`/updatepass/${phone}/${email}`);
        }
      }).catch(e => {
        console.log(e.response.data)
        addToast({
          type: 'error',
          title: 'Erro na requisição',
          description: 'Código inválido',
        });
      })
    } catch (err) {

      addToast({
        type: 'error',
        title: 'Erro na requisição',
        description: 'Código inválido',
      });
    }
  }

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000)
    }
  }, [time]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const [, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondLeft, secondRight] = String(seconds).padStart(2, '0').split('');


  const handleRequestCode = useCallback(async (event: FormEvent) => {
    event.preventDefault();

    api.post("/users/recoverypass", {
      phone,
      email,
    }).then(res => {
      if (res.status === 201) {
        setTime(req * 180)
        setReq(req+1)
      }
    }).catch(e =>
      console.log("Erro na requisição", `${e.response.data.message}`)
    )
  }, [email, phone, req])

  return (
    <>
      <Paralax />
      <Container >
        <BoxLogin >
          <h3>Digite o código que chegou em seu telefone</h3>
          <Form onSubmit={handleRecovery} >
            <input type="text" placeholder="Código" name="code" id="code" value={code} onChange={e => setCode(e.target.value)} />
            <button type="submit">Enviar</button>
          </Form>

          <button id="ReqCode" onClick={handleRequestCode} disabled={time > 0}>Reenviar código {time > 0 && `${minuteRight}:${secondLeft}${secondRight}`}</button>

          <Link to="/signup"><h4>Criar conta</h4></Link>
          <Link to="/signin"><h6>Já tenho conta</h6></Link>
        </BoxLogin>
      </Container>
    </>
  )
}