import styled from "styled-components";

export const Paralax = styled.div`
  display: flex;
  background: #121318;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  padding: 2rem;
`;

export const Box = styled.div`
  display: flex;
  max-width: 65rem;
  width: 100%;
  padding: 1rem;
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1F2025;
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;

  h3 {
    font-family: Oswaldb;
    color: #ff9c00;
    font-size: 24px;
  }
`;

export const Chaves = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  .Key {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .Fase1,
  .Fase2,
  .Fase3,
  .Fase4 {
    border-right: 1px solid #888;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    flex-direction: column;
    width: 15%;
    
  }

  .Fase5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23%;
    padding: 0.5rem;
    
  }

  .Fase6,
  .Fase7,
  .Fase8,
  .Fase9 {
    display: flex;
    border-left: 1px solid #888;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 15%;
  }

  .Fase1,
  .Fase9 {
    justify-content: space-between;
  }
  .Fase2,
  .Fase8 {
    height: 68vh;
    justify-content: space-between;
  }
  .Fase3,
  .Fase7 {
    height: 45vh;
    justify-content: space-between;
  }

  .User,
  .UserPar {
    padding: 0.2rem 0.5rem;
    width: 100%;
    border-radius: 5px;
    background: #383838;
    backdrop-filter: blur(42px);
    /* Note: backdrop-filter has minimal browser support */
    font-family: Oswaldr;
    font-size: 0.8rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: #fafafa
  }

  .Final{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    h4{
      color: #ff9c00
    }
  }
`;
