import React, { FormEvent, useState, } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { Paralax, Container, BoxLogin, Form } from "./styles";
import { ReactSVG } from 'react-svg'
import logo from '../../assets/logo.svg'


export default function RecoveryPass() {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const { addToast } = useToast();
  const history = useHistory();


  async function handleRecovery(event: FormEvent) {
    event.preventDefault();

    try {
      api.post("/users/recoverypass", {
        phone,
        email,
      }).then(res => {
        if (res.status === 201) {
          history.push(`/checkcode/${phone}/${email}`);
        }
      }).catch(e => {
        console.log("Erro na requisição", `${e.response.data.message}`)
        addToast({
          type: 'error',
          title: 'Erro na requisição',
          description: 'Ocorreu um erro, telefone/email divergentes do cadastro',
        });
    })
    } catch (err) {

      addToast({
        type: 'error',
        title: 'Erro na requisição',
        description: 'Ocorreu um erro, telefone/email divergentes do cadastro',
      });
    }
  }


  return (
    <>
      <Paralax />
      <Container >
        <ReactSVG src={logo} style={{
          marginBottom: '1rem'
        }} />
        <h2>EQuiz Premiado</h2>
        <BoxLogin >
          <h3>Recuperação de senha!</h3>
          <Form onSubmit={handleRecovery} >
            <input type="email" placeholder="Digite o email cadastrado" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
            <input type="text" placeholder="Digite o telefone cadastrado" name="phone" id="phone" value={phone} onChange={e => setPhone(e.target.value)} />
            <button type="submit">Enviar</button>
          </Form>

          <Link to="/signup"><h4>Criar conta</h4></Link>
          <Link to="/signin"><h6>Já tenho conta</h6></Link>
        </BoxLogin>
      </Container>
    </>
  )
}