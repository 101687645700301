import React from "react";
import { Footer } from "../../components/Footer";

import { Container, Content, Paralax } from './styles';

export function About() {
  return (
    <>
      <Paralax />
      <Container >
        <Content>
          <h2 id="topo">Quem somos?</h2>
          <p>Somos a empresa Ferreira Entretenimento e e-commerce, dona e administradora do site GAME OF PRICES, uma empresa séria especializada em intermediação de vendas de produtos e serviços diversos, através de leilões, com o intuito de oferecer a oportunidade de comprar um item dos sonhos com desconto de até 95% do valor de mercado. Oferecemos também uma plataforma desafiadora, EQUIZ PREMIADO, usando testes de conhecimento e agilidade. E o vencedor será recompensado com prêmio. Este app tem o intuito de incentivar o conhecimento, tudo isso acontece em um ambiente agradável e interativo.</p>
          <p>Nós começamos este projeto com sonhos e planos pequenos e contamos com cada um de nossos clientes, através de uma relação de confiança, para podermos crescer. O projeto incluí a preocupação de mostrar a nossa verdade e realidade para as pessoas que usam nosso site e aplicativo.</p>
          <p>Buscamos histórias duradouras como nossos parceiros e clientes, por isso faremos promoções constantes de nossos relacionamentos (usuários e parceiros) através de nossas redes sociais, bem como através de equipes de marketing pessoal e empresarial que constantemente temos. Queremos ver nosso cliente comprar um item de R$10.000,00 por R$200,00 ou quem sabe até menos. Promovemos a oportunidade de conhecer vários lugares do país ou fora dele, por pacotes especiais que sairão por 1/5 do valor pago no mercado.</p>
          <p>Estamos dando nossos primeiros passos no mercado, com ideias e buscas que surgiram em 12/06/2020 e sonhos começando a se concretizar a partir de então, nossa Empresa fundada em junho de 2021 por mim, Robson de Jesus Ferreira e minha esposa Camilla Sales de Oliveira Ferreira, ambos nascidos em Araxá MG. Eu, Robson, criado desde meus primeiros dias de vida em Tapira MG, formado em Administração de Empresas e Técnico em Mineração, pós graduado em Gestão Executiva de Investimentos. Trabalho na área de Mineração a mais de 17 anos, passei por vários cargos desde operacionais até gestão. Fiz minha carreira em uma empresa multinacional. 
Fundamos a empresa com vistas de revolucionar a interação cliente/empresa e abrir janelas que talvez jamais se abririam.</p>
          <br/>
          <h2>A empresa</h2>
          <p>
            A empresa está localizada na Rua dois, onde o fundador da empresa viveu suas primeiras conquistas e a partir da Cidade de Tapira-MG, queremos interagir com todo o Brasil por caminhos já possíveis.
          </p>
          <p>
            Nossos serviços são diferenciados, pois somos clientes também, antes de fundar nossa empresa, fomos o cliente, o usuário, buscamos transparecer seriedade, credibilidade, segurança, comodidade, acessibilidade e interação; enfim escrevemos exatamente como queríamos ser tratados como clientes e escrevemos nossos princípios a partir disso. “Calçar a botina do próximo”. Utilizamos as mais seguras plataformas de pagamentos, hospedagem, parceria com empresas reais e que estão ao seu lado. Nossa equipe é especializada em pessoas, em ambientes virtuais e comunicação, isso para não deixar nenhum detalhe passar sem que seja percebido e tratado. Assim sentiremos falta de cada cliente que não estiver sempre conosco, pois, precisamos de cada um para alcançar e promover o outro. Buscamos objetivo para promover projetos sociais que estarão descritos em breve nas páginas do site. Nos envie sua sugestão de obra social, em breve pode ser a sua indicação a ser parcialmente patrocinada por nós.
          </p>

          <h3>Nossa empresa - nossos princípios</h3>

          
          <h4>Missão:</h4>
          <p>Demonstrar transparência e segurança, construindo relações de longo prazo através de oportunidades e realização de sonhos.
          </p>
          <h4>Visão:</h4>
          <p>É emergir ao mercado diversificando a marca.</p>

          <h4>Valores:</h4>
          <p>• Simpatia e credibilidade;</p>
          <p>• Moralidade e promover nossos clientes,</p>
          <p>• Respeito e legalidade e</p>
          <p>• Qualidade e compromisso com as pessoas.</p>
         
        </Content>
      </Container>
      <Footer/>
    </>
  )
}





