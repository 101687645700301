import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #121318;
`;

export const Content = styled.div`
  width: 70vw;
  height: 80vh;
  background-color: #fcfcfc;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem;

  img {
    width: 30vw;
  }

  a {
    background-color: rgb(0, 0, 250);
    border: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    bottom: 0;
    margin-top: 1rem;
    text-decoration: none;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Name = styled.h2`
  font-family: birds;
  font-size: 40px;
  color: #121318;
  margin: 0.5rem 0;
`;

export const Info = styled.p`
  font-family: OswaldM;
`;

export const Merchan = styled.h3`
  font-family: birds;
`;
