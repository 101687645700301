import { createGlobalStyle } from "styled-components";
import birds from "../assets/fonts/angrybirds-regular.ttf";
import odibee from "../assets/fonts/OdibeeSans-Regular.ttf";
import oswaldB from "../assets/fonts/Oswald-Bold.ttf";
import oswaldE from "../assets/fonts/Oswald-ExtraLight.ttf";
import oswaldL from "../assets/fonts/Oswald-Light.ttf";
import oswaldM from "../assets/fonts/Oswald-Medium.ttf";
import oswaldR from "../assets/fonts/Oswald-Regular.ttf";
import oswaldS from "../assets/fonts/Oswald-SemiBold.ttf";

export default createGlobalStyle`
  @font-face {
    font-family: Odibee;
    src: url(${odibee});
  }

  @font-face {
    font-family: Birds;
    src: url(${birds});
  }

  @font-face {
    font-family: OswaldB;
    src: url(${oswaldB});
  }
  @font-face {
    font-family: OswaldE;
    src: url(${oswaldE});
  }
  @font-face {
    font-family: OswaldL;
    src: url(${oswaldL});
  }
  @font-face {
    font-family: OswaldM;
    src: url(${oswaldM});
  }
  @font-face {
    font-family: OswaldR;
    src: url(${oswaldR});
  }
  @font-face {
    font-family: OswaldS;
    src: url(${oswaldS});
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-soothing: antialiased; 
    background: #121318;
  }

  body, input, button{
    font: 16px sans-serif;
  }

  /* #root {
    max-width: 960px;
    margin: 0 auto;
    padding: 40px 20px;
  } */

  button {
    cursor: pointer;
  }
`;
