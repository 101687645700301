import React, { FormEvent, useState, } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { Paralax, Container, BoxLogin, Form } from "./styles"
import { ReactSVG } from 'react-svg'
import logo from '../../assets/logo.svg'


export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { signIn } = useAuth();
  const { addToast } = useToast();


  async function handleSignin(event: FormEvent) {
    event.preventDefault();

    try {
      await signIn({ email, password });
    } catch (err) {

      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
      });
    }
  }


  return (
    <>
      <Paralax />
      <Container >
        <ReactSVG src={logo} style={{
          marginBottom: '1rem'
        }}/>
        <h2>EQuiz Premiado</h2>
        <BoxLogin >
          <h3>Faça seu login</h3>
          <Form onSubmit={handleSignin} >
            <input type="email" placeholder="Digite seu email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
            <input type="password" placeholder="Digite sua senha" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
            <button type="submit">Entrar</button>
          </Form>

          <Link to="/signup"><h4>Criar conta</h4></Link>
          <Link to="/recoverypass"><h6>Esqueci minha senha</h6></Link>

        </BoxLogin>
      </Container>
    </>
  )
}