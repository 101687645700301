import React from "react";

import {
  Container,
  Content,
  Name,
  Info,
  Merchan,
} from './styles';

import logo from '../../assets/logo.png';
import { Link } from "react-router-dom";


export function Lose() {
  return (
    <Container>
      <Content>
      <img src={logo} alt="congratulations" />
        <Name>Não foi dessa vez!!!</Name>
        <Name>Hummmm! Você não se classificou para a próxima fase.</Name>
        <Info>Tente novamente, na próxima você chega la!</Info>
        <Merchan>Continue jogando e ganhando! Aqui seu conhecimento vale muito.</Merchan>
        <Link to="/wallet">Voltar para o painel</Link>
      </Content>
    </Container>
  )
}