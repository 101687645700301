/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from 'react-elastic-carousel';
import api from "../../services/api";
import { useHistory } from "react-router";

import {
  Paralax,
  Container,
  BoxGame,
  Count,
  Aviso,
  BoxQuestion,
  Question,
  Option,
  Button
} from './styles'
import { useToast } from "../../hooks/toast";

interface QuestionProps {
  Question_correct: string;
  Question_id: string;
  Question_option1: string;
  Question_option2: string;
  Question_option3: string;
  Question_question: string;

}

interface ResponseProps {
  name: number;
  value: string;
}


export default function PlayGame() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setIsActive] = useState<String[]>([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [response, setResponse] = useState<ResponseProps[]>([]);
  const [questions, setQuestions] = useState<QuestionProps[]>([]);
  const [time, setTime] = useState(239);
  const [indexAtual, setIndexAtual] = useState(0);
  const [start, setStart] = useState(0);
  const { addToast } = useToast();

  async function loadQuestions() {
    setLoading(true)
    await api
      .get(`/bedroons/getquestions`)
      .then(response => {
        setQuestions(response.data)
      }).catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }

  const confirmCup = useCallback(async() => {
    await api.post(`/users/confirmcup`, {
      value: 2,
    }).then(res => {
      if (res.status === 201) {
      }
    }).catch((e) => {
      addToast({
        type: 'error',
        title: 'Erro na transação',
        description: 'Coins insuficiente para participar da copa!',
      });
      history.push(`/wallet`);
    })
  },[])



  useEffect(() => {
    loadQuestions();
    confirmCup();
    setStart(new Date().getTime());
  }, [])

  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time) % 60;

  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondLeft, secondRight] = String(seconds).padStart(2, '0').split('');

  const handleChange = (value: string, name: number) => {
    const resp = response.filter(r => r.name !== name);
    setResponse([...resp, { name, value }]);
    isActive[name] = value;
  };

  const handleForm = useCallback(async () => {
    const end = new Date().getTime();
    const myTime = ((end - start) > 240000) ? 240000 : (end - start);

    const quantity = response.reduce((acc, r) => {
      return r.value === "correct" ? acc + 1 : acc;
    }, 0);
    await api
      .post('/bedroons/addresponse', {
        quantity: `${quantity}`,
        time: myTime,
        champion_value: '10',
        second_value: '2',
        price: '2'
      })
      .then(res => {
        history.push(`/info/${quantity}/${myTime}`)
      })
      .catch(error => {
        console.error(error)
      })
  }, [response, start, time]);


  useEffect(() => {
    setTimeout(() => {
      if (start > 0 && (new Date().getTime() - start) < 240000) {
        setTime((240000 - (new Date().getTime() - start))/1000);
      }
      if(start > 0 && (new Date().getTime() - start) >= 240000){
        handleForm()
      }
    }, 1000)
  }, [start, time])

  // useEffect(() => {
  //   if (time > 0) {
  //     setTimeout(() => {
  //       setTime(time - 1);
  //     }, 1000)
  //   }
  //   if(time <= 0){
  //     handleForm();
  //   }
  // }, [time]);

  return (
    <>
      <Paralax />
      <Container>
        <BoxGame>
          <Count>
            <h4>{minuteLeft}{minuteRight}</h4>
            <p>:</p>
            <h4>{secondLeft}{secondRight}</h4>
          </Count>
          <Aviso><p><strong>Importante: </strong>Responda todas as questões antes de enviar as respostas. </p><p>Para navegar pelas perguntas basta clicar nas setas.</p></Aviso>
          <Carousel
            className="Carousel"
            initialFirstItem={0}
            isRTL={false}
            itemsToShow={1}
            pagination={false}
            onChange={(currentItem) => setIndexAtual(currentItem.index)}

          >
            {
              !loading && questions.length > 0 && questions.map((q, index) => (

                <BoxQuestion key={q.Question_id}>
                  <strong style={{
                    fontSize: '1.5rem',
                    background: '#121318',
                    color: '#ff9c00',
                    padding: '0.5rem',
                    borderRadius: 5
                  }}>{index + 1} / {questions.length}</strong>
                  <Question>
                    {q.Question_question}
                  </Question>
                  {
                    index % 4 === 0 &&
                    <Option
                      value="correct"
                      name={`${index}`}
                      isActive={isActive[index] === "correct"}
                      onClick={() => handleChange("correct", index)}
                    >
                      <label>{q.Question_correct}</label>
                    </Option>
                  }

                  <Option
                    value="opt1"
                    name={`${index}`}
                    isActive={isActive[index] === "opt1"}
                    onClick={() => handleChange("opt1", index)}
                  >
                    <label>{q.Question_option1}</label>
                  </Option>

                  {
                    index % 4 === 1 &&
                    <Option
                      value="correct"
                      name={`${index}`}
                      isActive={isActive[index] === "correct"}
                      onClick={() => handleChange("correct", index)}
                    >
                      <label>{q.Question_correct}</label>
                    </Option>
                  }

                  < Option
                    value="opt2"
                    name={`${index}`}
                    isActive={isActive[index] === "opt2"}
                    onClick={() => handleChange("opt2", index)}
                  >
                    <label>{q.Question_option2}</label>
                  </Option>
                  {
                    index % 4 === 2 &&
                    <Option
                      value="correct"
                      name={`${index}`}
                      isActive={isActive[index] === "correct"}
                      onClick={() => handleChange("correct", index)}
                    >
                      <label>{q.Question_correct}</label>
                    </Option>
                  }
                  <Option
                    value="opt3"
                    name={`${index}`}
                    isActive={isActive[index] === "opt3"}
                    onClick={() => handleChange("opt3", index)}
                  >
                    <label>{q.Question_option3}</label>
                  </Option>
                  {
                    index % 4 === 3 &&
                    <Option
                      value="correct"
                      name={`${index}`}
                      isActive={isActive[index] === "correct"}
                      onClick={() => handleChange("correct", index)}
                    >
                      <label>{q.Question_correct}</label>
                    </Option>
                  }
                </BoxQuestion>
              ))
            }
          </Carousel>
          {!loading && questions.length > 0 && (questions.length - 1) === indexAtual &&
            <Button type="button" onClick={handleForm} >
              Enviar respostas
            </Button>
          }
        </BoxGame>
      </Container >
    </>
  )
};
