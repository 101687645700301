import React from "react";

import {
  Container,
  Content,
  Name,
  InfoText,
  Merchan,
} from './styles';

import first from '../../assets/primeiro.png';
// import second from '../../assets/segundo.png';
import { useParams } from "react-router";
import { Link } from "react-router-dom";

interface ParamProps {
  q: string
  time: string
}

export function Info() {
  const { q, time } = useParams() as ParamProps;
  return (
    <Container>
      <Content>
        <img src={first} alt="congratulations" />
        <Name>Parabéns!!!</Name>
        <Name>Você acaba de participar do torneio diário do EQuiz Premiado!</Name>
        <InfoText>`Você acertou {q} questões em {(`${Number(time)/1000}`).replace('.',',')} segundos. Tente novamente e continue melhorando seus resultados!`</InfoText>
        <Merchan>Continue jogando e ganhando! Aqui seu conhecimento vale muito.</Merchan>
        <Link to="/wallet">Voltar para o painel</Link>
      </Content>
    </Container>
  )
}