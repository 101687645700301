import styled from "styled-components";
import bg from "../../assets/paralax.jpg";

export const Paralax = styled.div`
  display: flex;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  height: 100vh;
  background-color:  #121318;
  padding: 2rem;
`;

export const BoxLogin = styled.div`
  display: flex;
  max-width: 30rem;
  width: 100%;
  padding: 2rem;
  background:  #1F2025;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
    font-family: OswaldS;
    font-size: 1.8rem;
    color: #ff9c00;
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;
  }

  h4 {
    font-family: OswaldS;
    font-size: 1rem;
    color: #fafafa;
    margin: 1rem 0;
    position: relative;
    transition: color 0.3s;
    text-decoration: none;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;

      width: 0px;
      height: 2px;

      background: linear-gradient(90deg, rgba(234, 29, 34, 0) 0%, #ff9c00 100%);
      transition: 0.7s ease;
    }

    &:hover {
      color: #ff9c00;
      &::before {
        width: 100%;
      }
    }
  }

  h6 {
    font-family: Oswaldl;
    font-size: 0.8rem;
    color: #fafafa;

    position: relative;
    transition: color 0.3s;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;

      width: 0px;
      height: 2px;

      background: linear-gradient(90deg, rgba(234, 29, 34, 0) 0%, #ff9c00 100%);
      transition: 0.7s ease;
    }

    &:hover {
      color: #ff9c00;
      &::before {
        width: 100%;
      }
    }
  }
  button#ReqCode{
    padding: 0.5rem;
    border-radius: 5px;
    border: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;

  input {
    margin-bottom: 1rem;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid #383838;
    background-color: #999;
    padding: 1.5rem 1rem;
  }

  button {
    background-color: #ff9c00;
    border-radius: 5px;
    padding: 1rem;
    color: #fff;
    border: none;
    transition: filter 0.3s;
    font-weight: bold;
    margin-bottom: 1rem;

    &:hover {
      filter: brightness(1.1);
    } 
  }

`;

export const Button = styled.button`
  display: flex;
  padding: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border: none;
  box-shadow: 0px 2px 4px #888888;
  transition: filter 0.3s;
  border-radius: 5px;

  &:hover {
    filter: brightness(0.95);
  }

  p {
    margin-left: 1rem;
  }
`;
