import React, { FormEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { maskPhone } from '../../utils/masks';
import { ReactSVG } from 'react-svg'
import logo from '../../assets/logo.svg'

import {
  Flex,
  Container,
  BoxLogin,
  Form,
} from './styles';


export default function SignUp() {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [telefone, setTelefone] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [terms, setTerms] = useState(false);
  const [oldYear, setOldYear] = useState(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleTerms = () => {
    setTerms(!terms);
  };

  const handleOldYear = () => {
    setOldYear(!oldYear);
  };

  const handleSignin = useCallback(async (event: FormEvent) => {
    event.preventDefault();

    console.log(terms, oldYear)

    if (!name || !lastName || !email || !password || !confirmPass || !telefone) {

      return alert("Preencha todos os campos!");
    }
    if (password !== confirmPass) {
      return alert("As senhas são diferentes");
    }
    if(!terms || !oldYear){
        return alert("Aceite os termos e confirme sua idade");
    }
    try {
      await api.post('/users', {
        name,
        last_name: lastName,
        email,
        telefone: telefone.replace(/\D/g, ""),
        password,
        is_admin: 0
      });

      signIn({ email, password });
    }
    catch (err) {
      console.log(err)
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro ao criar conta, cheque os campos e tente novante',
      });
    }

  }, [name, lastName, email, password, confirmPass, telefone, addToast, signIn, terms, oldYear])

  return (
    <Flex>
      <Container>
        <ReactSVG src={logo} style={{
          marginBottom: '1rem'
        }} />
        <h2>EQuiz Premiado</h2>

        <BoxLogin>
          <h3>Crie sua conta de maneira rápida e fácil</h3>
          <Form onSubmit={handleSignin} >
            <input type="text" placeholder="Digite seu nome" name="text" id="nome" value={name} onChange={e => setName(e.target.value)} />
            <input type="text" placeholder="Digite seu sobrenome" name="text" id="sobrenome" value={lastName} onChange={e => setLastName(e.target.value)} />
            <input type="email" placeholder="Digite seu email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
            <input type="text" placeholder="Digite seu telefone" name="email" id="email" value={telefone} onChange={e => setTelefone(maskPhone(e.target.value))} />
            <input type="password" placeholder="Digite sua senha" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
            <input type="password" placeholder="confirme sua senha" name="password" id="password" value={confirmPass} onChange={e => setConfirmPass(e.target.value)} />
            <button type="submit">Cadastrar</button>
            <div className='infoLegal'>
              <label>
                <input 
                  type='checkbox'
                  checked={oldYear}
                  onChange={handleOldYear}
                />

                Declaro, para os devidos fins, ser maior de 18 anos
              </label>
            </div>
            <div className='infoLegal'>
              <label>
                <input 
                  type='checkbox'
                  checked={terms}
                  onChange={handleTerms}
                />

                <p>
                  Declaro, para os devidos fins, que concordo com os <Link to="/privacypolicy">termos de uso e políticas de privacidade</Link>
                </p>
              </label>
            </div>
          </Form>

          <Link to="/signin"><h4>Já tenho conta</h4></Link>
        </BoxLogin>
      </Container>
    </Flex>
  )
}