/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from 'react-elastic-carousel';
import api from "../../services/api";
import { useHistory, useParams } from "react-router";

import {
  Paralax,
  Container,
  BoxGame,
  Count,
  Aviso,
  BoxQuestion,
  Question,
  Option,
  Button
} from './styles'

interface QuestionProps {
  order: number;
  question: {
    id: string;
    question: string;
    option1: string;
    option2: string;
    option3: string;
    correct: string;
  }
}

interface ParamsProps {
  id: string;
  round: string;
}

interface ResponseProps {
  name: number;
  value: string;
}


export default function Play() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setIsActive] = useState<String[]>([]);
  const history = useHistory();
  const [timeRoundOne, setTimeRoundOne] = useState('')
  const [timeRoundTwo, setTimeRoundTwo] = useState('')
  const [timeRoundThree, setTimeRoundThree] = useState('')
  const [timeRoundFour, setTimeRoundFour] = useState('')
  const [timeRoundFive, setTimeRoundFive] = useState('')

  const { id, round } = useParams() as ParamsProps;

  const [active, setActive] = useState(true);
  const [response, setResponse] = useState<ResponseProps[]>([]);
  const [questions, setQuestions] = useState<QuestionProps[]>([]);
  const [time, setTime] = useState(300);

  async function loadQuestions() {
    await api
      .get(`/bedroons/questions/${id}/${round}`)
      .then(response => {
        console.log(response.data)
        setQuestions(response.data)
      }).catch(err => {
        console.log(err);
      });
  }


  const loadTime = useCallback(() => {
    let finish; // hora da finalização
    switch (Number(round)) {
      case 1:
        finish = new Date(timeRoundOne);
        console.log(timeRoundOne)
        break;
      case 2:
        finish = new Date(timeRoundTwo);
        break;
      case 3:
        finish = new Date(timeRoundThree);
        break;
      case 4:
        finish = new Date(timeRoundFour);
        break;
      case 5:
        finish = new Date(timeRoundFive);
        break;
      default:
        finish = new Date();
        break;
    }
    const init = new Date(); // Data de hoje
    const diff = Math.abs(finish.getTime() - init.getTime()); // Subtrai uma data pela outra

    return (Math.ceil(diff / (1000))); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

  }, [round, timeRoundOne, timeRoundTwo, timeRoundThree, timeRoundFour, timeRoundFive, loadQuestions, time]);


  function startCountdown() {
    setActive(false);
  }


  const loadData = useCallback(async () => {
    await api
      .get(`/bedroons/${id}/${round}`)
      .then(response => {
        setTimeRoundOne(response.data[0].bedroom.round_one);
        setTimeRoundTwo(response.data[0].bedroom.round_two);
        setTimeRoundThree(response.data[0].bedroom.round_three);
        setTimeRoundFour(response.data[0].bedroom.round_four);
        setTimeRoundFive(response.data[0].bedroom.round_five);

      }).catch(err => {
        console.log(err.response.data);
      });
  }, [id, round])



  useEffect(() => {
    loadData();
    loadQuestions()
  }, [timeRoundOne])

  useEffect(() => {
    loadTime();
  }, [timeRoundOne])

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondLeft, secondRight] = String(seconds).padStart(2, '0').split('');

  const handleChange = (value: string, name: number) => {
    const resp = response.filter(r => r.name !== name);
    setResponse([...resp, { name, value }]);
    isActive[name] = value;
  };


  async function handleForm() {
    startCountdown();
    const quantity = response.reduce((acc, r) => {
      return r.value === "correct" ? acc + 1 : acc;
    }, 0);
    const bedroom_id = id;
    await api
      .post('/bedroons/responseusers', { quantity, time, bedroom_id, round })
      .then(res => {
        console.log(res.data)
        history.push(`/bedroon/${id}/${Number(round) + 1}`)
      })
      .catch(error => {
        console.error(error)
      })
  };


  useEffect(() => {
    if (active && time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000)
      if (round === "1" && timeRoundOne) {
        if (new Date() >= new Date(timeRoundOne)) {
          handleForm();
          history.push(`/bedroon/${id}/${Number(round) + 1}`)
        }
      }
      if (round === "2" && timeRoundTwo) {
        if (new Date() >= new Date(timeRoundTwo)) {
          handleForm();
          history.push(`/bedroon/${id}/${Number(round) + 1}`)
        }
      }
      if (round === "3" && timeRoundThree) {
        if (new Date() >= new Date(timeRoundThree)) {
          handleForm();
          history.push(`/bedroon/${id}/${Number(round) + 1}`)
        }
      }
      if (round === "4" && timeRoundFour) {
        if (new Date() >= new Date(timeRoundFour)) {
          handleForm();
          history.push(`/bedroon/${id}/${Number(round) + 1}`)
        }
      }
      if (round === "5" && timeRoundFive) {
        if (new Date() >= new Date(timeRoundFive)) {
          handleForm();
          history.push(`/bedroon/${id}/${Number(round) + 1}`)
        }
      }
    }
    if (time === 0) {
      handleForm();
      // history.push(`/bedroon/${id}/${Number(round) + 1}`)
    }
  }, [active, time]);

  return (
    <>
      <Paralax />
      <Container>
        <BoxGame>
          <Count>
            <h4>{minuteLeft}{minuteRight}</h4>
            <p>:</p>
            <h4>{secondLeft}{secondRight}</h4>
          </Count>
          <Aviso><p><strong>Importante: </strong>Responda todas as questões antes de enviar as respostas. </p><p>Para navegar pelas perguntas basta clicar nas setas.</p></Aviso>
          <Carousel initialFirstItem={0} isRTL={false} itemsToShow={1}>
            {
              questions.map(q => (

                <BoxQuestion key={q.order}>
                  <Question>
                    {q.question.question}
                  </Question>
                  {
                    q.order % 4 === 0 &&
                    <Option
                      value="correct"
                      name={`${q.order}`}
                      isActive={isActive[q.order] === "correct"}
                      onClick={() => handleChange("correct", q.order)}
                    >
                      <label>{q.question.correct}</label>
                    </Option>
                  }

                  <Option
                    value="opt1"
                    name={`${q.order}`}
                    isActive={isActive[q.order] === "opt1"}
                    onClick={() => handleChange("opt1", q.order)}
                  >
                    <label>{q.question.option1}</label>
                  </Option>

                  {
                    q.order % 4 === 1 &&
                    <Option
                      value="correct"
                      name={`${q.order}`}
                      isActive={isActive[q.order] === "correct"}
                      onClick={() => handleChange("correct", q.order)}
                    >
                      <label>{q.question.correct}</label>
                    </Option>
                  }

                  < Option
                    value="opt2"
                    name={`${q.order}`}
                    isActive={isActive[q.order] === "opt2"}
                    onClick={() => handleChange("opt2", q.order)}
                  >
                    <label>{q.question.option2}</label>
                  </Option>
                  {
                    q.order % 4 === 2 &&
                    <Option
                      value="correct"
                      name={`${q.order}`}
                      isActive={isActive[q.order] === "correct"}
                      onClick={() => handleChange("correct", q.order)}
                    >
                      <label>{q.question.correct}</label>
                    </Option>
                  }
                  <Option
                    value="opt3"
                    name={`${q.order}`}
                    isActive={isActive[q.order] === "opt3"}
                    onClick={() => handleChange("opt3", q.order)}
                  >
                    <label>{q.question.option3}</label>
                  </Option>
                  {
                    q.order % 4 === 3 &&
                    <Option
                      value="correct"
                      name={`${q.order}`}
                      isActive={isActive[q.order] === "correct"}
                      onClick={() => handleChange("correct", q.order)}
                    >
                      <label>{q.question.correct}</label>
                    </Option>
                  }
                </BoxQuestion>
              ))
            }
          </Carousel>
          <Button type="button" onClick={handleForm} >
            Enviar respostas
          </Button>
        </BoxGame>
      </Container >
    </>
  )
};
